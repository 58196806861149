<template>
  <div id="download">
    <h2>{{ $tc("general.download", 0) }}</h2>
    <p class="lead">
      <span class="headline">{{ $t("tweet-point.flyer") }}</span>
      <br />
      {{ $t("tweet-point.flyerInformation") }}<br />
    </p>
    <p class="lead">
      <a class="piwik_download" href="https://dl.tweet-point.com/public/Tweet-Point_Flyer_web.pdf"> <font-awesome-icon icon="download" class="icon" />&nbsp;{{ $tc("general.download") }}</a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h2 {
  padding-bottom: 1rem;
}

.lead {
  .headline {
    font-weight: bolder;
  }
}
</style>
