<template>
  <div>
    <navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/colorful-bytes/Navbar";

export default {
  components: {
    Navbar
  }
};
</script>
