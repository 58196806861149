<template>
  <pricing type="TOURISTPOINT" />
</template>

<script>
import Pricing from "@/components/general/Pricing";

export default {
  components: {
    Pricing
  }
};
</script>
