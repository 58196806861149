<template>
  <div class="container mt-4 mb-4" id="team">
    <h1 class="display-4 text-center mb-4">#Team</h1>

    <main>
      <div class="group">
        <img class="full-width" src="@/assets/img/team/group.webp" alt="Gruppenfoto" />
      </div>

      <div class="row">
        <div class="col-lg">
          <div class="row">
            <div class="col-sm">
              <div class="team-member">
                <div class="image">
                  <img src="@/assets/img/team/monika2.webp" class="full-width" alt="Monika Pobiruchin" />
                </div>
                <div class="description">
                  <h5>Monika Pobiruchin</h5>
                  <div class="subtitle">Data Analyst &amp; Controlling</div>
                  <div class="contact">
                    <font-awesome-icon icon="envelope" class="icon" />&nbsp;
                    <a
                      href="#"
                      class="cryptedmail contact-e-mail"
                      data-name="pobiruchin"
                      data-domain="colorful-bytes"
                      data-tld="com"
                      onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div class="team-member">
                <div class="image">
                  <img src="@/assets/img/team/daniel2.webp" class="full-width" style="transform: rotateY(180deg)" alt="Daniel Zsebedits" />
                </div>
                <div class="description">
                  <h5>Daniel Zsebedits</h5>
                  <div class="subtitle">Design &amp; Layout Expert</div>
                  <div class="contact">
                    <font-awesome-icon icon="envelope" class="icon" />&nbsp;
                    <a
                      href="#"
                      class="cryptedmail contact-e-mail"
                      data-name="zsebedits"
                      data-domain="colorful-bytes"
                      data-tld="com"
                      onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg">
          <div class="row">
            <div class="col-sm">
              <div class="team-member">
                <div class="image">
                  <img src="@/assets/img/team/richard2.webp" class="full-width" alt="Richard Zowalla" />
                </div>
                <div class="description">
                  <h5>Richard Zowalla</h5>
                  <div class="subtitle">Technology &amp; Integration Expert</div>
                  <div class="contact">
                    <font-awesome-icon icon="envelope" class="icon" />&nbsp;
                    <a
                      href="#"
                      class="cryptedmail contact-e-mail"
                      data-name="zowalla"
                      data-domain="colorful-bytes"
                      data-tld="com"
                      onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div class="team-member">
                <div class="image">
                  <img src="@/assets/img/team/martin2.webp" class="full-width" alt="Martin Wiesner" />
                </div>
                <div class="description">
                  <h5>Martin Wiesner</h5>
                  <div class="subtitle">Architect &amp; Project Manager</div>
                  <div class="contact">
                    <font-awesome-icon icon="envelope" class="icon" />&nbsp;
                    <a
                      href="#"
                      class="cryptedmail contact-e-mail"
                      data-name="wiesner"
                      data-domain="colorful-bytes"
                      data-tld="com"
                      onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Team"
};
</script>

<style lang="scss" scoped>
#team {
  .contact {
    .icon {
      color: $cb-cyan;
    }
  }

  .team-member {
    .description {
      margin-top: 0.5rem;
      margin-bottom: 2rem;

      .subtitle {
        margin-bottom: 0.4rem;
      }
    }
  }

  .group {
    margin-bottom: 2rem;
  }
}

@media (max-width: 767px) {
  #team {
    .team-member {
      img {
        max-width: 300px;
      }
    }
  }
}
</style>
