export default {
  data() {
    return {
      observeVisibilityOptions: {
        callback: this.visibilityChanged,
        throttle: 100
      }
    };
  },

  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        entry.target.classList.add("enter");
      }
    }
  }
};
