<template>
  <b-navbar toggleable="lg" type="light" fixed="top" class="cb-navbar">
    <div class="container">
      <b-navbar-brand :to="{ name: 'tweet-point-home' }">
        <img src="@/assets/img/logos/tp-logo.svg" class="d-inline-block align-top logo" alt="Tweet-Point Logo" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-collapse is-nav id="nav_collapse">
        <div class="navbar-nav ml-auto">
          <b-nav-item :to="{ name: 'tweet-point-home' }" exact-active-class="active">{{ $t("general.home") }}</b-nav-item>
          <b-nav-item :to="{ name: 'tweet-point-home', hash: '#features' }" active-class="active">{{ $t("general.features") }}</b-nav-item>
          <b-nav-item :to="{ name: 'tweet-point-home', hash: '#partner' }" active-class="active">{{ $t("general.references") }}</b-nav-item>
          <b-nav-item :to="{ name: 'tweet-point-pricing' }" active-class="active">{{ $t("general.pricing") }}</b-nav-item>
          <b-nav-item :to="{ name: 'tweet-point-home', hash: '#faq' }" active-class="active">{{ $t("general.faq") }}</b-nav-item>
          <li class="nav-item">
            <a class="nav-link" :href="supportLink" target="_blank">{{ $t("general.support") }}</a>
          </li>
          <b-nav-item :to="{ name: 'tweet-point-home', hash: '#contact' }" active-class="active">{{ $t("general.contact") }}</b-nav-item>
          <b-nav-item :to="{ name: 'tweet-point-team' }" active-class="active">{{ $t("general.team") }}</b-nav-item>
          <b-nav-item :to="{ name: 'tweet-point-home', hash: '#footer' }" active-class="active">{{ $t("general.imprint") }}</b-nav-item>
          <b-nav-item :to="{ name: 'tweet-point-data-privacy' }" active-class="active">{{ $t("general.dataPrivacy") }}</b-nav-item>
          <locale-switcher />
        </div>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import LocaleSwitcher from "@/components/general/LocaleSwitcher";

export default {
  computed: {
    locale() {
      return this.$i18n.locale.toLowerCase();
    },
    supportLink() {
      let locale = this.locale;
      if (locale === "fr") {
        locale = "en";
      }
      return `https://support.colorful-bytes.com/${locale}/support/`;
    }
  },
  components: {
    LocaleSwitcher
  }
};
</script>

<style lang="scss" scoped>
nav {
  background-color: white;
}

.navbar-brand {
  padding: 0;
}

.logo {
  height: $navbar-logo-height;
}
</style>
