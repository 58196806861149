<template>
  <div id="contact">
    <h2>{{ $t("general.contactUs") }}</h2>
    <p class="lead">{{ $t("general.contactHeadline") }}</p>
    <div class="row">
      <div class="col-md-6" id="contact-information">
        <section class="contact-box">
          <div class="contact-icon">
            <font-awesome-icon icon="phone-alt" class="icon" />
          </div>
          <div>
            <p class="lead">
              <span class="headline">{{ $t("general.callUs") }}</span>
            </p>
            <p class="lead">
              <a href="tel:+4972647029772">+49 7264 7029772</a>
            </p>
          </div>
        </section>

        <section class="contact-box">
          <div class="contact-icon">
            <font-awesome-icon icon="envelope" class="icon" />
          </div>
          <div>
            <p class="lead">
              <span class="headline">{{ $t("general.mailUs") }}</span>
            </p>
            <p class="lead">
              <a
                href="#"
                class="cryptedmail contact-e-mail"
                data-name="info"
                data-domain="colorful-bytes"
                data-tld="com"
                onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
              ></a>
            </p>
          </div>
        </section>

        <section class="contact-box">
          <div class="contact-icon">
            <font-awesome-icon icon="pencil-alt" class="icon" />
          </div>
          <div>
            <p class="lead">
              <span class="headline">{{ $t("general.writeUs") }}</span>
            </p>
            <p class="lead">
              Colorful Bytes GmbH<br />
              Blumenstraße 20<br />
              D-74906 Bad Rappenau
            </p>
          </div>
        </section>
        <section class="contact-box">
          <div class="contact-icon">
            <font-awesome-icon icon="share-alt" class="icon" />
          </div>
          <div>
            <p class="lead">
              <span class="headline">{{ $t("general.connectWithUs") }}</span>
            </p>
            <p class="lead">
              <a href="https://twitter.com/colbts" rel="noreferrer" target="_blank"> <font-awesome-icon :icon="['fab', 'twitter']" class="icon" /> {{ $t("general.followUs") }} </a>
            </p>
          </div>
        </section>
      </div>

      <div class="col-md-6">
        <section class="contact-box" id="contact-form-wrapper" :style="{ display: cookiesAccepted ? 'flex' : '' }">
          <div class="contact-icon">
            <i class="fa fa-envelope" aria-hidden="true"></i>
          </div>
          <div class="contact-content">
            <p class="lead">
              <span>{{ $t("general.useContactForm") }}</span>
            </p>
            <b-alert v-model="success" variant="success" dismissible>
              <strong>{{ $t("general.thankYouForYourMessage") }}</strong> {{ $t("general.weWillContactYou") }}
            </b-alert>
            <b-alert v-model="invalidMail" variant="warning" dismissible>
              <strong>{{ $t("general.invalidEmail") }}</strong> {{ $t("general.pleaseUseValidEmail") }}
            </b-alert>
            <b-alert v-model="error" variant="warning" dismissible>{{ $t("general.contactRequestCouldNotBeSent") }}</b-alert>

            <form id="contact-form" ref="form" autocomplete="off" @submit.prevent="onSubmit">
              <div class="input-group">
                <i><font-awesome-icon icon="user-circle" /></i>
                <input type="text" v-model="name" name="name" required maxlength="256" class="form-control" :placeholder="$t('general.name')" />
              </div>
              <div class="input-group">
                <i><font-awesome-icon icon="envelope" /></i>
                <input type="email" v-model="email" name="email" maxlength="256" required class="form-control" :placeholder="$t('general.email')" />
              </div>
              <div class="input-group">
                <i><font-awesome-icon icon="phone-alt" /></i>
                <input type="tel" v-model="phoneNumber" required name="tel" class="form-control" :placeholder="$t('general.phoneNumber')" />
              </div>
              <textarea rows="5" v-model="message" name="message" required maxlength="1024" class="form-control" :placeholder="$t('general.message')"></textarea>

              <button id="submit" class="btn btn-lg btn-outline-cb-cyan full-width mb-3">
                <font-awesome-icon icon="circle-notch" v-if="loading" class="spin" /><span v-else>{{ $t("general.submit") }}</span>
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sendContactForm } from "@/api";

export default {
  name: "Contact",

  props: {
    type: {
      type: String,
      default: "TWEETPOINT"
    }
  },

  data() {
    return {
      name: null,
      email: null,
      message: null,
      phoneNumber: null,
      loading: false,
      success: false,
      error: false,
      invalidMail: false
    };
  },

  computed: {
    ...mapState({
      cookiesAccepted: state => state.cookiesAccepted
    })
  },

  methods: {
    async onSubmit() {
      if (this.$refs.form && !this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }
      this.resetAlerts();
      this.loading = true;
      try {
        const response = await sendContactForm(this.name, this.email, this.phoneNumber, this.message, this.$i18n.locale, this.type);
        if (response.success && !response.invalidMail) {
          this.success = true;
          this.reset(true);
        } else if (response.invalidMail) {
          this.invalidMail = true;
          this.reset();
        } else {
          this.error = true;
          this.reset();
        }
      } catch (e) {
        this.error = true;
        this.reset();
      }
    },

    resetAlerts() {
      this.error = false;
      this.invalidMail = false;
      this.success = false;
    },

    reset(form) {
      this.loading = false;

      if (form) {
        this.name = null;
        this.email = null;
        this.phoneNumber = null;
        this.message = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#contact {
  h2 {
    padding-right: 4rem;
    padding-bottom: 1rem;
  }

  .contact-box {
    display: flex;
    margin-bottom: 1rem;

    .lead {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;

      span {
        font-size: 1.4rem;
        font-weight: bolder;
      }
    }

    .contact-icon {
      font-size: 1.4rem;
      color: $cb-grey;
      margin-right: 1rem;
      width: 16px;
    }

    .contact-content {
      width: 100%;
    }
  }

  padding-bottom: 4rem;
}

#contact-information {
  > p.lead {
    margin-bottom: 2rem;
  }
}

/* Contact Form */
#contact-form-wrapper.contact-box {
  display: none;
}

#contact-form {
  color: $cb-cyan;
  padding-top: 0.3rem;

  .input-group {
    border: 1px solid $cb-cyan;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 0.3rem 0.2rem;

    > i {
      font-size: 1.4rem;
      padding-top: 0.35rem;
      padding-left: 1rem;
    }
  }

  input,
  textarea {
    font-size: 1.2rem;
    background: white;
    outline: none;
    box-shadow: none;
    color: $cb-cyan;
  }

  input {
    border: none;
  }

  textarea {
    padding: 1rem;
    border: 1px solid $cb-cyan;
    border-radius: 4px;
    margin-bottom: 1rem;
    resize: vertical;
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $cb-cyan;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $cb-cyan;
    opacity: 1;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $cb-cyan;
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $cb-cyan;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $cb-cyan;
  }

  .btn {
    cursor: pointer;
  }
}
</style>
