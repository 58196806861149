<template>
  <div class="row slide-in-on-scroll" v-observe-visibility="observeVisibilityOptions">
    <div :class="['col-md-7', { 'order-md-12': imgLeft }]">
      <h2 class="featurette-heading">{{ title }}</h2>
      <div>
        <p v-if="text != null" class="lead">{{ text }}</p>
        <i18n v-else-if="textPath != null" :path="textPath" tag="p" class="lead">
          <template v-slot:shy>&shy;</template>
        </i18n>
      </div>
    </div>
    <div :class="['col-md-5', { 'order-md-1': imgLeft }]">
      <!--<img class="featurette-image img-fluid mx-auto" src="@/assets/img/cell-phone-1245663_1280.webp"-->
      <!--alt="Cell Phone">-->
      <div class="featurette-image" :style="`background: ${imgStyle}`"></div>
      <div class="text-muted">{{ copyright }}</div>
    </div>
  </div>
</template>

<script>
import visibilityChange from "@/mixins/visibility-change";

export default {
  mixins: [visibilityChange],

  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String
    },
    textPath: {
      type: String
    },
    img: {
      type: String
    },
    imgLeft: {
      type: Boolean,
      default: false
    },
    imgBackgroundSize: {
      type: String,
      default: "cover"
    },
    imgBackgroundPosition: {
      type: String,
      default: "center center"
    },
    imgBackgroundRepeat: {
      type: String,
      default: "no-repeat"
    },
    copyright: {
      type: String,
      default: ""
    }
  },

  computed: {
    imgStyle() {
      return this.img != null ? `url(${this.img}) ${this.imgBackgroundPosition} / ${this.imgBackgroundSize} ${this.imgBackgroundRepeat};` : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.featurette-heading {
  font-weight: normal;
  letter-spacing: -0.05rem;
  color: $cb-dark-grey;
}

@media (min-width: $sm) {
  .featurette-heading {
    font-size: 40px;
  }
}

.featurette-image {
  height: 100%;
  width: 100%;
  min-height: 240px;
}
</style>
