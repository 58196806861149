<template>
  <div>
    <div class="cubes-container-static">
      <div class="cube-container">
        <div class="cube cube-white">
          <div class="front"></div>
          <div class="back"></div>
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>
      <div class="cube-container">
        <div class="cube cube-white">
          <div class="front"></div>
          <div class="back"></div>
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>
      <div class="cube-container">
        <div class="cube cube-white">
          <div class="front"></div>
          <div class="back"></div>
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>
      <div class="cube-container">
        <div class="cube cube-white">
          <div class="front"></div>
          <div class="back"></div>
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>
    </div>
    <!-- 
      <div class="cube-container">
        <div class="cube cube-magenta cube-rotating">
          <div class="front"></div>
          <div class="back"></div>
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>
    -->

    <!-- 
      <div class="cubes-container">
        <div class="cube-container">
          <div class="cube cube-green">
            <div class="front"></div>
            <div class="back"></div>
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="cube-container">
          <div class="cube cube-magenta">
            <div class="front"></div>
            <div class="back"></div>
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="cube-container">
          <div class="cube cube-orange">
            <div class="front"></div>
            <div class="back"></div>
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
        <div class="cube-container">
          <div class="cube cube-cyan">
            <div class="front"></div>
            <div class="back"></div>
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div>
      </div>
     -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
