const URL_BASE = process.env.VUE_APP_API_URL;
const URL_CONTACT_FORM = URL_BASE + "send/message";
const URL_PACKAGES = URL_BASE + "packages";
const URL_QUOTE = URL_BASE + "send/quote";

const headersGet = {
  Accept: "application/json"
};

const headersPost = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

export const sendContactForm = (name, email, phoneNumber, message, locale = "de", type = "TWEETPOINT") => {
  const body = {
    name,
    email,
    message,
    phoneNumber
  };

  return fetch(`${URL_CONTACT_FORM}?locale=${locale}&type=${type}`, {
    headers: headersPost,
    method: "POST",
    body: JSON.stringify(body)
  }).then(function (res) {
    return res.json();
  });
};

export const fetchPackages = (locale = "de", type = "TWEETPOINT") => {
  return fetch(`${URL_PACKAGES}?locale=${locale}&type=${type}`, {
    headers: headersGet
  }).then(res => res.json());
};

/**
 *
 * @param {*} name
 * @param {*} email
 * @param {*} message
 * @param {*} phoneNumber
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} eventName
 * @param {*} eventLocation
 * @param {*} spotsCount
 * @param {*} daysCount
 * @param {*} totalPrice
 * @param {*} requestedPackages
 */
export const sendQuote = (name, email, message, phoneNumber, startDate, endDate, eventName, eventLocation, spotsCount, units, totalPrice, requestedPackages, locale = "de", type = "TWEETPOINT") => {
  const body = {
    name,
    email,
    message: message != null ? message : "",
    phoneNumber,
    startDate,
    endDate,
    eventName: eventName != null && eventName !== "" ? eventName : eventLocation,
    eventLocation,
    spotsCount: spotsCount != null ? spotsCount : 1,
    units,
    totalPrice,
    requestedPackages
  };

  return fetch(`${URL_QUOTE}?locale=${locale}&type=${type}`, {
    headers: headersPost,
    method: "POST",
    body: JSON.stringify(body)
  }).then(res => res.json());
};
