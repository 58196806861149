<template>
  <div>
    <h1 class="hidden">Colorful Bytes | Digital dabei</h1>

    <carousel />

    <div class="container">
      <featurette />
      <hr class="divider" />
      <partner class="slide-in-on-scroll" v-observe-visibility="observeVisibilityOptions" />
      <hr class="divider" />
      <faq class="slide-in-on-scroll" v-observe-visibility="observeVisibilityOptions" />
      <hr class="divider" />
      <downloads class="slide-in-on-scroll" v-observe-visibility="observeVisibilityOptions" />
      <hr class="divider" />
      <contact class="slide-in-on-scroll" v-observe-visibility="observeVisibilityOptions" />
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/tweet-point/Carousel";
import Contact from "@/components/general/Contact";
import Downloads from "@/components/tweet-point/Downloads";
import Faq from "@/components/general/Faq";
import Featurette from "@/components/tweet-point/Featurette";
import Partner from "@/components/tweet-point/Partner";
import visibilityChange from "@/mixins/visibility-change";

export default {
  name: "Home",

  mixins: [visibilityChange],

  components: {
    Carousel,
    Contact,
    Downloads,
    Faq,
    Featurette,
    Partner
  }
};
</script>
