<template>
  <div class="feature" :style="{ background: pack.selected || pack.included ? pack.color : '', 'border-color': pack.color, color: pack.selected || pack.included ? 'white' : '' }">
    <div :class="[{ row: !isMainPackage, 'align-items-center': !isMainPackage, 'd-flex': isMainPackage, 'flex-column': isMainPackage }, 'h-100']">
      <div :class="[{ 'col-md': !isMainPackage }]">
        <div :class="[{ row: !isMainPackage, 'd-flex': isMainPackage, 'flex-column': isMainPackage }, 'align-items-center']">
          <div class="col-sm-auto">
            <div class="icon">
              <div :class="[{ 'mb-3': isMainPackage, 'mt-3': isMainPackage }, 'logo-wrapper']" v-if="pack.icon && pack.icon.find(i => i === 'cb')">
                <img class="logo" src="@/assets/img/logos/tourist-point-logo.svg" alt="Tweet-Point Logo" v-if="type === 'TOURISTPOINT'" />
                <img class="logo" src="@/assets/img/logos/tp-logo.svg" alt="Tweet-Point Logo" v-else />
              </div>
              <font-awesome-icon :icon="pack.icon" v-else :style="{ color: pack.selected || pack.included ? 'white' : '' }" />
            </div>
          </div>
          <div class="col-sm">
            <h5>
              {{ pack.name }} <span v-if="pack.amount > 1 && (pack.selected || pack.included)">{{ pack.amount }}x</span>
            </h5>
            <div v-html="pack.descriptionBase"></div>
          </div>
        </div>
      </div>
      <div v-if="isMainPackage" class="flex-grow-1"></div>
      <div :class="[{ 'col-md-auto': !isMainPackage }]">
        <div class="d-flex align-items-center justify-content-end">
          <div>
            <div v-if="basePrice === 0">{{ $t("general.calculationBasedOnHours") }}</div>
            <div v-else>
              <div class="price">
                <span class="high">€</span>
                <span v-if="basePrice">{{ basePrice.toLocaleString() }}</span>
                <span class="high">**</span>
                <span class="period">/{{ type === "TWEETPOINT" ? $t("general.day") : $tc("general.month") }}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="checkbox-wrapper" v-if="pack.included">
              <div><font-awesome-icon :icon="['far', 'check-square']" /></div>
            </div>
            <div class="checkbox-wrapper" @click="$emit('toggle', pack)" v-else>
              <div v-if="pack.selected"><font-awesome-icon :icon="['far', 'check-square']" /></div>
              <div v-else><font-awesome-icon :icon="['far', 'square']" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pack: {
      type: Object,
      required: true
    },

    packageType: {
      type: String
    },

    type: {
      type: String,
      default: "TWEETPOINT"
    }
  },

  computed: {
    basePrice() {
      if (this.pack != null) {
        const basePricing = this.pack.packagePricing.find(p => p.amountPerUnit === 1);
        if (basePricing != null) {
          return basePricing.pricePerUnit;
        }
      }
      return 0;
    },

    isMainPackage() {
      return this.packageType === "MAIN";
    }
  }
};
</script>

<style lang="scss" scoped>
.feature {
  padding: 0.8rem;
  margin-bottom: 0.8rem;
  border: 1px solid $cb-light-grey;
  transition: background-color 0.2s ease, color 0.2s ease;
  height: calc(100% - 0.8rem);
}

.price {
  .high {
    font-size: 1.7rem;
    vertical-align: top;
  }

  font-size: 2.4rem;

  .period {
    font-size: 1rem;
  }
}

ul {
  margin-bottom: 0;
}

.icon {
  width: 64px;
  text-align: center;
  color: $cb-dark-grey;
  font-size: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.logo-wrapper {
  background: white;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  padding: 0.4rem;
}

.logo {
  width: 100%;
  height: auto;
}

.checkbox-wrapper {
  padding: 1rem;
  font-size: 2rem;
}
</style>
