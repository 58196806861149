<template>
  <div id="features">
    <div id="steps-container" class="row">
      <step :title="$t('general.consulting')" textPath="tourist-point.consultingDescription" :img="imgConsulting" />
      <step :title="$t('general.adaption')" textPath="tourist-point.adaptionDescription" :img="imgCustomizing" />
      <step :title="$t('general.visualization')" textPath="tourist-point.visualizationDescription" :img="imgDisplay" />
      <step :title="$t('general.protection')" textPath="tourist-point.protectionDescription" :img="imgCustomizing" />
    </div>

    <hr class="divider" />
    <featurette-item id="featurette-1" :title="$t('tourist-point.featurette1Headline')" textPath="tourist-point.featurette1Description" :img="imgSccon" />
    <hr class="divider" />
    <featurette-item id="featurette-2" :title="$t('tourist-point.featurette2Headline')" textPath="tourist-point.featurette2Description" :img="imgWomanPc" :imgLeft="true" imgBackgroundSize="contain" imgBackgroundPosition="center top" />
    <hr class="divider" />
    <featurette-item id="featurette-3" :title="$t('tourist-point.featurette3Headline')" textPath="tourist-point.featurette3Description" :img="imgSponsors" imgBackgroundSize="contain" />
    <hr class="divider" />
    <featurette-item id="featurette-4" :title="$t('tourist-point.featurette4Headline')" textPath="tourist-point.featurette4Description" :img="imgLightBulbs" :imgLeft="true" />
    <hr class="divider" />
    <featurette-item id="featurette-5" :title="$t('tourist-point.featurette5Headline')" textPath="tourist-point.featurette5Description" :img="imgInfluencer" />
  </div>
</template>

<script>
import FeaturetteItem from "@/components/general/FeaturetteItem";
import Step from "@/components/general/Step";

import imgConsulting from "@/assets/img/steps-customizing.webp";
import imgCustomizing from "@/assets/img/steps-customizing.webp";
import imgDisplay from "@/assets/img/steps-display.webp";

import imgSccon from "@/assets/img/sccon19-2.webp";
import imgWomanPc from "@/assets/img/woman-pc.webp";
import imgLightBulbs from "@/assets/img/light-bulbs.webp";
import imgSponsors from "@/assets/img/featurette-sponsors.webp";
import imgInfluencer from "@/assets/img/arrows-2023448_720.webp";

export default {
  computed: {
    imgConsulting() {
      return imgConsulting;
    },
    imgCustomizing() {
      return imgCustomizing;
    },
    imgDisplay() {
      return imgDisplay;
    },
    imgSccon() {
      return imgSccon;
    },
    imgWomanPc() {
      return imgWomanPc;
    },
    imgSponsors() {
      return imgSponsors;
    },
    imgLightBulbs() {
      return imgLightBulbs;
    },
    imgInfluencer() {
      return imgInfluencer;
    }
  },

  components: {
    FeaturetteItem,
    Step
  }
};
</script>
