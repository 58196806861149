<template>
  <div id="app">
    <main>
      <router-view class="router" />
      <site-footer />
    </main>
    <cookie-consent />
  </div>
</template>

<script>
import CookieConsent from "@/components/general/CookieConsent";
import SiteFooter from "@/components/general/Footer";

export default {
  components: {
    CookieConsent,
    SiteFooter
  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>

<style lang="scss" scoped>
main {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.router {
  flex: 1 1 auto;
  padding-bottom: 3rem;
  padding-top: $navbar-height;
}
</style>
