<template>
  <div>
    <div id="pricing" class="container mt-4 mb-4" v-if="cookiesAccepted">
      <h1 class="display-4 text-center mb-4">{{ $t("general.productConfigurator") }}</h1>

      <b-alert v-model="success" variant="success" dismissible>
        <strong>{{ $t("general.thankYouForYourMessage") }}</strong> {{ $t("general.yourRequestHasBeenReceived") }}
      </b-alert>
      <b-alert v-model="error" variant="warning" dismissible>{{ $t("general.technicalError") }}</b-alert>

      <div v-if="packages">
        <h3>{{ type === "TOURISTPOINT" ? $t("tourist-point.myEvent") : $t("tweet-point.myEvent") }}</h3>
        <div class="event-information">
          <form class="full-width" ref="form" autocomplete="off" v-on:submit.prevent="onSubmit" sticky-container>
            <div class="row" v-if="type === 'TOURISTPOINT'">
              <div class="col-lg">
                <div class="form-group form-row">
                  <label for="startDate" class="col-sm-4 col-form-label">{{ $t("tourist-point.eventLocation") }}</label>
                  <div class="col-sm-8">
                    <input type="text" required class="form-control mb-2 mr-sm-2" :placeholder="$t('general.location')" v-model="eventLocation" />
                  </div>
                </div>
              </div>
              <div class="col-lg-auto">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label :class="[{ active: months === monthOption }, 'btn', 'btn-outline-cb-cyan']" v-for="monthOption in monthOptions" :key="monthOption">
                    <input type="radio" :id="`option-${monthOption}`" :value="monthOption" v-model="months" />{{ monthOption }} {{ $tc("general.month", monthOption) }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-4">
                <div class="form-group form-row">
                  <label for="startDate" class="col-sm-4 col-form-label">{{ $t("general.name") }}</label>
                  <div class="col-sm-8">
                    <input type="text" required class="form-control mb-2 mr-sm-2" :placeholder="$t('tweet-point.eventName')" v-model="eventName" />
                  </div>
                </div>
                <div class="form-group form-row">
                  <label for="startDate" class="col-sm-4 col-form-label">{{ $t("general.location") }}</label>
                  <div class="col-sm-8">
                    <input type="text" required class="form-control mb-2 mr-sm-2" :placeholder="$t('tweet-point.eventLocation')" v-model="eventLocation" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group form-row">
                  <label for="startDate" class="col-sm-4 col-form-label">{{ $t("general.startDate") }}</label>
                  <div class="col-sm-8">
                    <input type="date" required class="form-control mb-2 mr-sm-2" id="startDate" :min="currentDate" v-model="eventStartDate" />
                  </div>
                </div>
                <div class="form-group form-row">
                  <label for="endDate" class="col-sm-4 col-form-label">{{ $t("general.endDate") }}</label>
                  <div class="col-sm-8">
                    <input type="date" required class="form-control mb-2 mr-sm-2" id="endDate" :min="eventStartDate" :max="maxEndDate" v-model="eventEndDate" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group form-row">
                  <label for="startDate" class="col-sm-4 col-form-label">{{ $t("general.spots") }}*</label>
                  <div class="col-sm-8">
                    <input type="number" required min="1" class="form-control mb-2 mr-sm-2" :placeholder="$t('general.numberOfSpots')" v-model.number="eventDisplays" />
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-sm-4 col-form-label">{{ $t("general.numberOfDays") }}</label>
                  <label class="col-sm-8 col-form-label">{{ units }}</label>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div v-sticky sticky-offset="{ top: 72 }" on-stick="onStick" :class="['checkout-information', { sticky }]">
          <div class="row align-items-center">
            <div class="col-auto d-none d-lg-block">
              <h5 class="mb-0">{{ $t("general.noncommitalPackagePrice") }}</h5>
            </div>
            <div class="col-auto">
              <span class="price" v-if="total !== baseTotal">
                <span class="high">€</span><span class="striked">{{ baseTotal.toLocaleString() }}</span> <span class="high"></span>
              </span>
              &nbsp;
              <span class="price"><span class="high">€</span>{{ total.toLocaleString() }}<span class="high">**</span></span>
            </div>
            <div class="col"></div>
            <div class="col-auto">
              <button @click="onSubmit" :class="['btn', { 'btn-cb-cyan': !sticky, 'btn-outline-cb-white-white-orange': sticky }]" :disabled="(!eventEndDate && type === 'TWEETPOINT') || !mainPackageSelected">
                {{ $t("general.getQuote") }}
              </button>
            </div>
          </div>
        </div>

        <h4 class="mt-4">{{ $tc("general.mainPackage", mainPackages != null ? mainPackages.length : 2) }}</h4>
        <div class="row">
          <div class="col-md" v-for="p in mainPackages" :key="p.objectID">
            <feature :type="type" :pack="p" packageType="MAIN" @toggle="togglePackageSelected" />
          </div>
        </div>

        <div v-if="mainPackageSelected">
          <div v-if="includedPackages != null && includedPackages.length > 0">
            <h4 class="mt-4">{{ $tc("general.includedPackage", includedPackages.length) }}</h4>
            <feature :type="type" v-for="p in includedPackages" :key="p.objectID" :pack="p" />
          </div>

          <div v-if="excludedFeaturePackages != null && excludedFeaturePackages.length > 0">
            <h4 class="mt-4">{{ $tc("general.extraPackage", excludedFeaturePackages.length) }}</h4>
            <feature :type="type" v-for="p in excludedFeaturePackages" :key="p.objectID" :pack="p" @toggle="togglePackageSelected" />
          </div>

          <div v-if="excludedAdditionalPackages != null && excludedAdditionalPackages.length > 0">
            <h4 class="mt-4">{{ $t("general.additionalRequests") }}</h4>
            <feature :type="type" v-for="p in excludedAdditionalPackages" :key="p.objectID" :pack="p" @toggle="togglePackageSelected" />
          </div>
        </div>

        <div>
          <small>*{{ type === "TOURISTPOINT" ? $t("tourist-point.spotsDefinition") : $t("tweet-point.spotsDefinition") }}</small>
          <br />
          <small>**{{ $t("general.excludingTaxes") }}</small>
        </div>
      </div>
      <div v-else-if="!packages && !error" class="loading-spinner">
        <font-awesome-icon icon="circle-notch" class="spin" />
      </div>
    </div>
    <div v-else-if="!cookiesAccepted">
      <div class="container">
        {{ $t("general.acceptCookiesForCalculation") }}
      </div>
    </div>
    <b-modal v-model="showModal" title="Fast geschafft - nur noch Ihre Kontaktdaten" cancel-title="Zurück" ok-title="Absenden" variant="primary" hide-footer no-fade>
      <b-alert v-model="invalidMail" variant="warning" dismissible>
        <strong>{{ $t("general.invalidEmail") }}</strong> {{ $t("general.pleaseUseValidEmail") }}
      </b-alert>

      <form id="contact-form" ref="form-modal" autocomplete="off" v-on:submit.prevent="onSubmitModal">
        <div class="form-group form-row">
          <label for="name" class="col-sm-4 col-form-label">{{ $t("general.yourName") }}</label>
          <div class="col-sm-8">
            <input type="text" required class="form-control mb-2 mr-sm-2" :placeholder="$t('general.name')" v-model="contactName" />
          </div>
        </div>
        <div class="form-group form-row">
          <label for="name" class="col-sm-4 col-form-label">{{ $t("general.yourEmail") }}</label>
          <div class="col-sm-8">
            <input type="email" required class="form-control mb-2 mr-sm-2" :placeholder="$t('general.email')" v-model="contactEmail" />
          </div>
        </div>
        <div class="form-group form-row">
          <label for="name" class="col-sm-4 col-form-label">{{ $t("general.yourPhoneNumber") }}</label>
          <div class="col-sm-8">
            <input type="tel" required class="form-control mb-2 mr-sm-2" :placeholder="$t('general.phoneNumber')" v-model="contactPhone" />
          </div>
        </div>
        <div class="form-group form-row">
          <label for="name" class="col-sm-4 col-form-label">{{ $t("general.message") + " (" + $t("general.optional") + ")" }}</label>
          <div class="col-sm-8">
            <textarea class="form-control mb-2 mr-sm-2" rows="3" :placeholder="$t('general.message') + ' (' + $t('general.optional') + ')'" v-model="contactMessage" />
          </div>
        </div>
        <hr />
        <div class="text-right">
          <button type="button" class="btn btn-secondary mr-2" @click="closeModal">{{ $t("general.back") }}</button>
          <button class="btn btn-cb-cyan" :disabled="(!eventEndDate && type === 'TWEETPOINT') || !mainPackageSelected">
            <font-awesome-icon icon="circle-notch" v-if="loading" class="spin" /><span v-else>{{ $t("general.getQuote") }}</span>
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Feature from "@/components/general/Feature";

import { dateDiffInDays } from "@/util";
import { sendQuote, fetchPackages } from "@/api";
import { mapState } from "vuex";

export default {
  name: "Pricing",

  props: {
    type: {
      type: String,
      default: "TWEETPOINT"
    },
    unit: {
      type: String,
      default: "DAY"
    }
  },

  data() {
    return {
      eventName: "",
      eventLocation: "",
      eventStartDate: new Date().toLocaleDateString("en-CA"),
      eventEndDate: null,
      eventDisplays: null,

      currentDate: new Date().toLocaleDateString("en-CA"),

      collapseEventInformation: false,
      showModal: false,

      contactName: null,
      contactEmail: null,
      contactPhone: null,
      contactMessage: null,

      packages: null,

      loading: false,
      success: false,
      error: false,
      invalidMail: false,

      sticky: false,

      monthOptions: [1, 6, 12],
      months: 1
    };
  },

  computed: {
    units() {
      if (this.type === "TOURISTPOINT") {
        return this.months;
      } else {
        if (this.eventStartDate && this.eventEndDate) {
          const diff = dateDiffInDays(this.eventStartDate, this.eventEndDate) + 1;
          return diff < 1 ? 0 : diff;
        }
      }
      return 0;
    },

    locale() {
      return this.$i18n.locale;
    },

    maxEndDate() {
      let date = new Date(this.eventStartDate);
      date.setDate(date.getDate() + 28);
      return date.toLocaleDateString("en-CA");
    },

    total() {
      return this.calculateTotal();
    },

    baseTotal() {
      return this.calculateTotal(false);
    },

    mainPackageSelected() {
      if (this.mainPackages != null) {
        return this.mainPackages.find(p => p.selected);
      }
      return false;
    },

    devicePackage() {
      if (this.packages != null) {
        return this.packages.find(p => p.name === "Geräte-Paket");
      }
      return null;
    },

    includedPackages() {
      return [...this.featurePackages, ...this.additionalPackages].filter(p => p.included);
    },

    excludedFeaturePackages() {
      if (this.featurePackages != null) {
        return this.featurePackages.filter(p => !p.included);
      }
      return [];
    },

    excludedAdditionalPackages() {
      if (this.additionalPackages != null) {
        return this.additionalPackages.filter(p => !p.included);
      }
      return [];
    },

    mainPackages() {
      if (this.packages != null) {
        return this.packages.filter(p => p.packageType === "MAIN");
      }
      return [];
    },

    featurePackages() {
      if (this.packages != null) {
        return this.packages.filter(p => p.packageType === "FEATURE");
      }
      return [];
    },

    additionalPackages() {
      if (this.packages != null) {
        return this.packages.filter(p => p.packageType === "REQUEST");
      }
      return [];
    },

    ...mapState({
      cookiesAccepted: state => state.cookiesAccepted
    })
  },

  methods: {
    togglePackageSelected(p) {
      if (p.packageType === "MAIN") {
        this.mainPackages.forEach(p1 => {
          if (p.objectID !== p1.objectID) {
            p1.selected = false;
          }
        });
        [...this.featurePackages, ...this.additionalPackages].forEach(p1 => {
          if (p.includedPackages.find(ip => ip.objectID === p1.objectID)) {
            p1.included = true;
          } else {
            p1.included = false;
          }
        });
      }
      if (this.type !== "TWEETPOINT" || p.name !== "Geräte-Paket") {
        p.selected = !p.selected;
      }
    },

    onSubmit() {
      if (this.$refs.form && !this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }
      this.showModal = true;
    },

    async onSubmitModal() {
      const form = this.$refs["form-modal"];
      if (form && !form.checkValidity()) {
        form.reportValidity();
        return;
      }
      this.resetAlerts();
      this.loading = true;
      try {
        const response = await sendQuote(
          this.contactName,
          this.contactEmail,
          this.contactMessage,
          this.contactPhone,
          new Date(this.eventStartDate).toISOString(),
          new Date(this.eventEndDate).toISOString(),
          this.eventName,
          this.eventLocation,
          this.eventDisplays,
          this.units,
          this.total,
          this.packages.filter(p => p.selected || p.included),
          this.$i18n.locale,
          this.type
        );
        if (response.success && !response.invalidMail) {
          window.scrollTo(0, 0);
          this.success = true;
          this.reset(true);
        } else if (response.invalidMail) {
          this.invalidMail = true;
          this.reset();
        } else {
          window.scrollTo(0, 0);
          this.error = true;
          this.reset();
        }
      } catch (e) {
        this.closeModal();
        window.scrollTo(0, 0);
        this.error = true;
        this.reset();
      }
    },

    resetAlerts() {
      this.error = false;
      this.invalidMail = false;
      this.success = false;
    },

    reset(form) {
      this.loading = false;

      if (form) {
        this.contactName = null;
        this.contactEmail = null;
        this.contactPhone = null;
        this.contactMessage = null;
        this.showModal = false;
        this.eventName = null;
        this.eventLocation = null;
        this.eventDisplays = null;
        this.eventStartDate = new Date().toLocaleDateString("en-CA");
        this.eventEndDate = null;
        this.packages.forEach(p => {
          p.selected = false;
        });
      }
    },

    calculateDevicePackages() {
      if (this.devicePackage && this.mainPackageSelected && this.type === "TWEETPOINT") {
        if (this.eventDisplays - this.mainPackageSelected.includedDevices > 0) {
          this.devicePackage.selected = true;
          this.devicePackage.amount = Math.ceil((this.eventDisplays - this.mainPackageSelected.includedDevices) / this.devicePackage.includedDevices) * this.units;
        } else {
          this.devicePackage.selected = false;
          this.devicePackage.amount = 0;
        }
      }
    },

    calculateTotal(includeDiscounts = true) {
      let total = 0;
      if (this.packages && this.mainPackageSelected) {
        total += this.packages.reduce((acc, p) => {
          if (p.selected && (!p.included || p.name === "Geräte-Paket")) {
            let pricing;
            if (includeDiscounts) {
              pricing = p.packagePricing.filter(pr => pr.amountPerUnit <= p.amount).sort((pr1, pr2) => pr2.amountPerUnit - pr1.amountPerUnit)[0];
            } else {
              pricing = p.packagePricing.find(p => p.amountPerUnit === 1);
            }
            if (pricing != null) {
              return pricing.pricePerUnit * (p.amount - pricing.discountInUnit) + acc;
            }
          }
          return acc;
        }, 0);
      }
      return total;
    },

    closeModal() {
      this.showModal = false;
    },

    onStick(e) {
      this.sticky = e.sticked;
    },

    async fetchPackages() {
      try {
        this.packages = (await fetchPackages(this.locale, this.type)).packages.map(p => {
          this.$set(p, "selected", false);
          this.$set(p, "included", false);
          this.$set(p, "amount", this.type === "TOURISTPOINT" ? 1 : 0);
          return p;
        });
      } catch (e) {
        this.error = true;
      }
    }
  },

  watch: {
    eventDisplays(newValue) {
      if (newValue < 0) {
        this.eventDisplays = 0;
        return;
      }
      this.calculateDevicePackages();
    },

    mainPackageSelected() {
      this.calculateDevicePackages();
    },

    locale() {
      this.packages = null;
      this.fetchPackages();
    },

    units(newValue) {
      this.packages.forEach(p => (p.amount = newValue));
      this.calculateDevicePackages();
    }
  },

  created() {
    document.addEventListener("scroll", this.onScroll);
    setTimeout(this.fetchPackages, 300);
  },

  destroyed() {
    document.removeEventListener("scroll", this.onScroll);
  },

  components: {
    Feature
  }
};
</script>

<style lang="scss" scoped>
#pricing {
  margin-bottom: 3rem;

  .event-information {
    background: white;
    padding: 0.8rem 0.8rem 0.3rem 0.8rem;
    border: 1px solid $cb-light-grey;
    border-bottom: 0;
  }

  .checkout-information {
    background: white;
    padding: 0.8rem;
    border: 1px solid $cb-light-grey;
    transition: background-color 0.2s ease, color 0.2s ease;

    &.sticky {
      color: white;
      background: $cb-orange;
      border-color: $cb-orange;
    }
  }

  h1.headline,
  h2.headline {
    margin-bottom: 2rem;
  }

  .headline {
    font-weight: 300;
    letter-spacing: -0.05rem;
  }

  .price {
    .high {
      font-size: 1.7rem;
      vertical-align: top;
    }

    font-size: 2.4rem;
    line-height: 1;

    .period {
      font-size: 1rem;
    }
  }

  .form-group {
    margin-bottom: 0;

    .custom-control {
      text-align: right;
    }
  }
}

.loading-spinner {
  font-size: 2rem;
  color: $cb-cyan;
  text-align: center;
  margin-top: 2rem;
}

.striked {
  text-decoration: line-through red;
}
</style>
