<template>
  <cookie-law buttonText="Cookies erlauben" :buttonDecline="true" buttonDeclineText="Ablehnen" @accept="onAccept" @decline="onDecline">
    <i18n path="general.cookieMessage" tag="div" slot="message">
      <template v-slot:datePrivacyDeclaration>
        <router-link to="data-privacy">{{ $t("general.dataPrivacyDeclaration") }}</router-link>
      </template>
    </i18n>
  </cookie-law>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import { mapMutations } from "vuex";
import { SET_ACCEPTED } from "@/store/mutation-types";

export default {
  methods: {
    onAccept() {
      this.setAccepted(true);
      window._paq.push(["rememberConsentGiven"]); // Matomo consent given
    },

    onDecline() {
      this.setAccepted(false);
      window._paq.push(["forgetConsentGiven"]); // Matomo consent revoked
      document.location.reload();
    },

    ...mapMutations({
      setAccepted: SET_ACCEPTED
    })
  },

  components: {
    CookieLaw
  }
};
</script>

<style lang="scss" scoped></style>
