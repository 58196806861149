<template>
  <footer id="footer">
    <div class="container">
      <router-link :to="{ name: 'colorful-bytes-home' }">
        <div><img class="logo" src="@/assets/img/logo-bildmarke.webp" alt="Colorful Bytes Logo Bildmarke" /></div>
      </router-link>
      <div class="row">
        <div class="col-md-4">
          <p>
            Colorful Bytes GmbH<br />
            Blumenstraße 20<br />
            D-74906 Bad Rappenau
          </p>
          <p>
            <font-awesome-icon icon="phone-alt" class="icon" /> <a href="tel:+4972647029772">+49 7264 7029772</a><br />
            <font-awesome-icon icon="envelope" class="icon" />&nbsp;
            <a
              href="#"
              class="cryptedmail contact-e-mail"
              data-name="info"
              data-domain="colorful-bytes"
              data-tld="com"
              onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
            ></a>
          </p>
        </div>
        <div class="col-md-4">
          <p>
            Handelsregister<br />
            Amtsgericht Stuttgart, HRB 758952
          </p>
          <p>
            {{ $t("general.vat") }}<br />
            DE309647220
          </p>
        </div>
        <div class="col-md-4">
          <p>
            {{ $t("general.managingBoard") }}<br />
            Dr. Monika Pobiruchin<br />
            Martin Wiesner<br />
            Dr. Richard Zowalla<br />
            Daniel Zsebedits
          </p>
        </div>
      </div>
      <span @click="changeCookieSettings" class="cookie-settings">{{ $t("general.changeCookieSettings") }}</span>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    changeCookieSettings() {
      localStorage.removeItem("cookie:accepted");
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  background: $cb-dark-grey;
  color: $cb-light-grey;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.logo {
  height: 80px;
  margin-bottom: 1.5rem;
  margin-left: -4px;
}

.icon {
  width: 1.2rem;
}

.cookie-settings {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: white;
  }
}
</style>
