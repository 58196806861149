import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import store from "./store";

import { BootstrapVue } from "bootstrap-vue";
import Sticky from "vue-sticky-directive";
import VueMatomo from "vue-matomo";
import VueObserveVisibility from "vue-observe-visibility";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDownload,
  faFileImport,
  faObjectGroup,
  faUserShield,
  faAd,
  faChartLine,
  faEnvelope,
  faPhoneAlt,
  faUserCircle,
  faShareAlt,
  faHandshake,
  faFileAlt,
  faHdd,
  faGlobeAmericas,
  faPencilAlt,
  faCircleNotch,
  faQrcode,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faSquare, faFileAlt as farFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faGlobeAmericas,
  faFileImport,
  faObjectGroup,
  faUserShield,
  faAd,
  faChartLine,
  faUserCircle,
  farFileAlt,
  faFileAlt,
  faCheckSquare,
  faSquare,
  faTwitter,
  faHdd,
  faHandshake,
  faShareAlt,
  faPhoneAlt,
  faEnvelope,
  faDownload,
  faPencilAlt,
  faCircleNotch,
  faQrcode,
  faQuestionCircle
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(Sticky);
Vue.use(VueMatomo, {
  host: process.env.VUE_APP_MATOMO_URL,
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
  trackerFileName: "piwik",
  router: router,
  enableLinkTracking: true,
  requireConsent: true,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
