<template>
  <div class="col-lg slide-in-on-scroll step" v-observe-visibility="observeVisibilityOptions">
    <img :src="img" :alt="title" />
    <h2>{{ title }}</h2>
    <div>
      <p v-if="text != null" class="lead">{{ text }}</p>
      <i18n v-else-if="textPath != null" :path="textPath" tag="p" class="lead">
        <template v-slot:shy>&shy;</template>
      </i18n>
    </div>
  </div>
</template>

<script>
import visibilityChange from "@/mixins/visibility-change";

export default {
  mixins: [visibilityChange],

  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String
    },
    textPath: {
      type: String
    },
    img: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.step {
  // margin-bottom: 1.5rem;
  // text-align: center;
  // p {
  //   margin-right: 0.75rem;
  //   margin-left: 0.75rem;
  // }
  img {
    width: auto;
    height: 100px;
    margin-bottom: 0.75rem;
  }
  h2 {
    font-weight: normal;
  }
}
</style>
