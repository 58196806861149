import Vue from "vue";
import VueRouter from "vue-router";

import ColorfulBytes from "@/views/colorful-bytes/ColorfulBytes";
import ColorfulBytesHome from "@/views/colorful-bytes/Home";
import ColorfulBytesContact from "@/views/colorful-bytes/Contact";
import TweetPoint from "@/views/tweet-point/TweetPoint";
import TweetPointHome from "@/views/tweet-point/Home";
import TouristPoint from "@/views/tourist-point/TouristPoint";
import TouristPointHome from "@/views/tourist-point/Home";
import TweetPointPricing from "@/views/tweet-point/Pricing";
import TouristPointPricing from "@/views/tourist-point/Pricing";
import DataPrivacy from "@/views/general/DataPrivacy";
import Team from "@/views/general/Team";

Vue.use(VueRouter);

const colorfulBytes = {
  path: "/",
  component: ColorfulBytes,
  meta: {
    title: "Colorful Bytes | Digital dabei"
  },
  children: [
    {
      path: "",
      name: "colorful-bytes-home",
      component: ColorfulBytesHome,
      meta: {
        title: "Colorful Bytes | Digital dabei"
      }
    },
    {
      path: "team",
      name: "colorful-bytes-team",
      alias: "/tweet-point/team",
      component: Team,
      meta: {
        title: "Colorful Bytes | Digital dabei | Team"
      }
    },
    {
      path: "contact",
      name: "colorful-bytes-contact",
      component: ColorfulBytesContact,
      meta: {
        title: "Colorful Bytes | Digital dabei | Kontakt"
      }
    },
    {
      path: "data-privacy",
      name: "colorful-bytes-data-privacy",
      component: DataPrivacy,
      meta: {
        title: "Colorful Bytes | Digital dabei | Datenschutzerklärung"
      }
    }
  ]
};

const tweetPoint = {
  path: "/tweet-point",
  component: TweetPoint,
  meta: {
    title: "Tweet-Point | Infodisplays und Twitterwalls für Ihr Event"
  },
  children: [
    {
      path: "",
      name: "tweet-point-home",
      component: TweetPointHome,
      meta: {
        title: "Tweet-Point | Infodisplays und Twitterwalls für Ihr Event"
      }
    },
    {
      path: "team",
      name: "tweet-point-team",
      component: Team,
      meta: {
        title: "Tweet-Point | Team"
      }
    },
    {
      path: "data-privacy",
      name: "tweet-point-data-privacy",
      component: DataPrivacy,
      meta: {
        title: "Tweet-Point | Datenschutzerklärung"
      }
    },
    {
      path: "pricing",
      name: "tweet-point-pricing",
      component: TweetPointPricing,
      meta: {
        title: "Tweet-Point | Digital dabei | Preise"
      }
    }
  ]
};

const touristPoint = {
  path: "/tourist-point",
  component: TouristPoint,
  meta: {
    title: "Tourist-Point | Digitale Lösungen für die Tourismus- und Erholungsbranche!"
  },
  children: [
    {
      path: "",
      name: "tourist-point-home",
      component: TouristPointHome,
      meta: {
        title: "Tourist-Point | Digitale Lösungen für die Tourismus- und Erholungsbranche!"
      }
    },
    {
      path: "team",
      name: "tourist-point-team",
      component: Team,
      meta: {
        title: "Tourist-Point | Team"
      }
    },
    {
      path: "data-privacy",
      name: "tourist-point-data-privacy",
      component: DataPrivacy,
      meta: {
        title: "Tourist-Point | Datenschutzerklärung"
      }
    },
    {
      path: "pricing",
      name: "tourist-point-pricing",
      component: TouristPointPricing,
      meta: {
        title: "Tourist-Point | Preise"
      }
    }
  ]
};

const routes = [];

switch (process.env.VUE_APP_TYPE) {
  case "TWEETPOINT":
    tweetPoint.path = "/";
    routes.push(tweetPoint, { path: "*", redirect: "/" });
    break;
  case "TOURISTPOINT":
    touristPoint.path = "/";
    routes.push(touristPoint, { path: "*", redirect: "/" });
    break;
  default:
    routes.push(colorfulBytes, tweetPoint, touristPoint, { path: "*", redirect: "/" });
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      let top = 0;
      if (to.hash === "#footer") {
        top = document.querySelector(to.hash) ? document.querySelector(to.hash).offsetTop + 500 : top;
      } else {
        top = document.querySelector(to.hash) ? document.querySelector(to.hash).offsetTop - 100 : top;
      }
      return window.scrollTo({
        top,
        behavior: "smooth"
      });
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Tweet-Point | Infodisplays und Twitterwalls für Ihr Event";
  next();
});

export default router;
