<template>
  <div>
    <div class="highlight text-white">
      <div class="container">
        <div class="row">
          <div class="col-md d-none d-lg-block">
            <div class="cubes">
              <cubes />
            </div>
          </div>
          <div class="col-md">
            <div class="heading">
              <h1>{{ $t("colorful-bytes.title") }}</h1>
              <p>{{ $t("colorful-bytes.claim") }}</p>
              <router-link :to="{ name: 'colorful-bytes-home', hash: '#tiles' }">{{ $t("general.moreInformation") }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="subheading pt-3 pb-3 d-flex justify-content-center flex-wrap">
        <div class="ml-2 mr-2">
          <strong>{{ $t("general.digitalSignageFor") }}:</strong>
        </div>
        <div class="ml-2 mr-2">
          <router-link :to="{ name: 'tweet-point-home', query: { slide: 2 } }">{{ $t("general.stages") }}</router-link>
        </div>
        <div class="ml-2 mr-2">
          <router-link :to="{ name: 'tweet-point-home', query: { slide: 1 } }">{{ $t("general.fairs") }}</router-link>
        </div>
        <div class="ml-2 mr-2">
          <router-link :to="{ name: 'tweet-point-home', query: { slide: 3 } }">{{ $t("general.congresses") }}</router-link>
        </div>
        <div class="ml-2 mr-2">
          <router-link :to="{ name: 'tweet-point-home', query: { slide: 6 } }">{{ $t("general.events") }}</router-link>
        </div>
        <div class="ml-2 mr-2">
          <router-link :to="{ name: 'tourist-point-home', query: { slide: 1 } }">{{ $t("general.tourism") }}</router-link>
        </div>
        <div class="ml-2 mr-2">
          <router-link :to="{ name: 'tourist-point-home' }">{{ $t("general.recreation") }}</router-link>
        </div>
        <div class="ml-2 mr-2">
          <router-link :to="{ name: 'tourist-point-home', query: { slide: 2 } }">{{ $t("general.marketing") }}</router-link>
        </div>
      </div>
    </div>

    <hr class="mb-5 mt-0" />

    <div class="container">
      <div class="tiles" id="tiles">
        <div class="row">
          <div class="col-md">
            <tile :to="{ name: 'tweet-point-home' }" :title="$t('tweet-point.title')" claimPath="tweet-point.claim" :actionText="$t('general.toTheProduct')">
              <template #bottom>
                <div class="image-container" id="image-tweet-point"></div>
              </template>
            </tile>
          </div>
          <div class="col-md">
            <tile :to="{ name: 'tourist-point-home' }" :title="$t('tourist-point.title')" claimPath="tourist-point.claim" :actionText="$t('general.toTheProduct')" background="#1b998b">
              <template #bottom>
                <div class="image-container">
                  <div class="tv tv-horizontal">
                    <img class="d-block img-fluid full-width" :src="calendar" alt="Vertikales Layout" />
                  </div>
                </div>
              </template>
            </tile>
          </div>
          <div class="col-md">
            <tile :to="{ name: 'colorful-bytes-contact' }" :title="$t('it-services.title')" claimPath="it-services.claim" background="#e71d36" fadeOut>
              <template #bottom>
                <div class="image-container">
                  <div class="tv">
                    <img class="d-block img-fluid full-width" :src="sccon" alt="Vertikales Layout" />
                  </div>
                </div>
              </template>
            </tile>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cubes from "@/components/general/Cubes";
import Tile from "@/components/general/Tile";
import sccon from "@/assets/img/screenshot-sccon.webp";
import calendar from "@/assets/img/tourist-point/Tourist-Point-Veranstaltungskalender.webp";

export default {
  computed: {
    sccon() {
      return sccon;
    },

    calendar() {
      return calendar;
    }
  },

  components: {
    Cubes,
    Tile
  }
};
</script>

<style lang="scss" scoped>
.highlight {
  // background: rgb(27, 153, 139);
  // background: linear-gradient(127deg, rgba(27, 153, 139, 1) 10%, rgba(167, 203, 103, 1) 35%, rgba(244, 96, 54, 1) 65%, rgba(231, 29, 54, 1) 90%);

  // background: rgb(167, 203, 103);
  // background: linear-gradient(130deg, rgba(167, 203, 103, 1) 13%, rgba(27, 153, 139, 1) 35%, rgba(27, 153, 139, 1) 45%, rgba(231, 29, 54, 1) 45%, rgba(231, 29, 54, 1) 75%, rgba(244, 96, 54, 1) 90%);

  // background: rgb(167, 203, 103);
  // background: linear-gradient(
  //   145deg,
  //   rgba(167, 203, 103, 1) 0%,
  //   rgba(167, 203, 103, 1) 25%,
  //   rgba(27, 153, 139, 1) 25%,
  //   rgba(27, 153, 139, 1) 45%,
  //   rgba(231, 29, 54, 1) 45%,
  //   rgba(236, 56, 54, 1) 75%,
  //   rgba(240, 74, 54, 1) 75%,
  //   rgba(244, 96, 54, 1) 100%
  // );

  background: rgb(167, 203, 103);
  background: linear-gradient(142deg, rgba(167, 203, 103, 1) 13%, rgba(27, 153, 139, 1) 42%, rgba(231, 29, 54, 1) 75%, rgba(244, 96, 54, 1) 90%);

  max-height: calc(100vh - #{$navbar-height});
  height: 600px;
}

.cubes {
  margin-top: 1rem;
}

.heading {
  padding-top: 11rem;
  h1 {
    font-size: 56px;
  }
  p {
    padding-top: 1rem;
    font-size: larger;
  }
  a {
    color: white;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}

.tiles {
  margin-bottom: 1rem;
}

.image-container {
  perspective: 800px;
  margin-top: 1rem;
  flex: 1;
}

.tv {
  border-radius: 3px;
  border: solid #13202c;
  border-right-color: #060b0f;
  border-bottom-color: #060b0f;
  box-shadow: 1px 1px 10px black;
  width: 60%;
  margin-left: 30%;
  border-width: 6px;
  transform: rotate3d(0, 1, 0, -15deg);

  img {
    z-index: -1000;
  }
}

.tv-horizontal {
  width: 100%;
  margin-left: 0;
  margin-top: 1.4rem;
}

#image-tweet-point {
  background: url("~@/assets/img/sccon19-2.webp") center center / cover no-repeat;
  // box-shadow: 1px 1px 10px black;
  border: 2px solid white;
}

@media (min-width: $md) {
  // .tiles {
  // margin-left: 1rem;
  // margin-right: 1rem;
  // }
}
</style>
