<template>
  <router-link :to="to">
    <div class="tile" :style="{ background }">
      <h3>{{ title }}</h3>
      <i18n :path="claimPath" tag="p">
        <template v-slot:br><br /></template>
        <template v-slot:ndash>&ndash;</template>
        <template v-slot:shy>&shy;</template>
      </i18n>
      <router-link :to="to">{{ actionText != null ? actionText : $t("general.moreInformation") }}</router-link>
      <slot name="bottom" />
      <div v-if="fadeOut" class="fade-out" :style="{ background: `linear-gradient(0deg, ${background} 0%, rgba(0, 0, 0, 0) 100%)` }"></div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    claimPath: {
      type: String
    },
    to: {
      type: Object,
      default() {
        return { name: "colorful-bytes-home" };
      }
    },
    actionText: {
      type: String
    },
    background: {
      type: String,
      default: "#a7cb67"
    },
    fadeOut: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.tile {
  height: 480px;
  color: white;
  padding: 1.4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  position: relative;

  p {
    font-size: larger;
  }

  a {
    color: white;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}

.fade-out {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10%;
}
</style>
