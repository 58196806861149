<template>
  <div class="container mt-4">
    <contact type="COLORFULBYTES" />
  </div>
</template>

<script>
import Contact from "@/components/general/Contact";

export default {
  components: {
    Contact
  }
};
</script>
