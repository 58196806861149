<template>
  <div id="faq">
    <h2>FAQ &mdash; {{ $t("general.frequentlyAskedQuestions") }}</h2>
    <p class="lead" v-for="n in 7" :key="n">
      <span class="headline">{{ $t(`${prefix}.faq${n}Question`) }}</span>
      <br />
      <i18n :path="`${prefix}.faq${n}Answer`" tag="span">
        <template #br><br /></template>
        <template #ndash>&ndash;</template>
        <template #shy>&shy;</template>
        <template #aPriceCalculator>
          <router-link :to="{ name: `${prefix}-pricing` }">{{ $t("general.priceCalculator") }}</router-link>
        </template>
        <template #aAdditionalPackage>
          <router-link :to="{ name: `${prefix}-pricing` }">{{ $t("general.additonalPackage") }}</router-link>
        </template>
        <template #aContactForm>
          <router-link :to="{ name: `${prefix}-home`, hash: '#contact' }">{{ $t("general.contactForm") }}</router-link>
        </template>
        <template #aKnowledgeBase>
          <a :href="supportLink" target="_blankj">{{ $t("general.knowledgeBase") }}</a>
        </template>
      </i18n>
    </p>
  </div>
</template>

<script>
export default {
  name: "Faq",

  props: {
    prefix: {
      type: String,
      default: "tweet-point"
    }
  },

  computed: {
    locale() {
      return this.$i18n.locale.toLowerCase();
    },
    supportLink() {
      let locale = this.locale;
      if (locale === "fr") {
        locale = "en";
      }
      return `https://support.colorful-bytes.com/${locale}/support/`;
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  padding-bottom: 1rem;
}

.lead {
  .headline {
    font-weight: bolder;
  }
}
</style>
