const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const dateDiffInDays = (a, b) => {
  if (!a || !b) {
    return 0;
  }

  // Parse Strings to JavaScript Dates
  a = new Date(a);
  b = new Date(b);

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const addScript = (src, location = document.head) => {
  const s = document.createElement("script");
  s.setAttribute("src", src);
  location.appendChild(s);
};
