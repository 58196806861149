<template>
  <div id="data-privacy" class="container mt-4 mb-4">
    <h2>{{ $t("general.dataPrivacyDeclaration") }}</h2>
    <div>
      <div v-if="locale === 'en' || locale === 'fr'">
        <h3 id="dsg-general-intro"></h3>
        <p>
          This data protection declaration clarifies the type, scope and purpose of the processing of personal data (hereinafter referred to as "data") within our online offer and the associated websites, functions and contents as well as
          external online presences, such as our Social Media Profile (hereinafter referred to collectively as "online offer"). With regard to the terms used, e.g. "processing" or "controller", we refer to the definitions in Art. 4 of the
          General Data Protection Regulation (GDPR).
          <br />
          <br />
        </p>
        <h3 id="dsg-general-controller">Controller</h3>
        <p>
          <span class="tsmcontroller"
            >Colorful Bytes GmbH<br />
            Blumenstraße 20<br />
            74906 Bad Rappenau<br />
            E-Mailadresse:
            <a href="#" class="cryptedmail" data-name="info" data-domain="colorful-bytes" data-tld="com" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a
            ><br />
            Managing Directors: Dr. Monika Pobiruchin, Martin Wiesner, Richard Zowalla, Daniel Zsebedits<br />
            <router-link :to="{ name: 'colorful-bytes-home', hash: '#footer' }">{{ $t("general.imprint") }}</router-link>
            <br />
          </span>
        </p>
        <h3 id="dsg-general-datatype">Types of data processed:</h3>
        <p>
          - Inventory data (e.g., names, addresses).<br />
          - Contact data (e.g., e-mail, telephone numbers).<br />
          - Content data (e.g., text input, photographs, videos).<br />
          - Usage data (e.g., visited websites, interest in content, access times).<br />
          - Meta/communication data (e.g., device information, IP addresses).<br />
        </p>
        <h3 id="dsg-general-datasubjects">Categories of data subjects</h3>
        <p>Visitors and users of the online offer (in the following we will refer to the persons concerned collectively as "users").<br /></p>
        <h3 id="dsg-general-purpose">Purpose of the processing</h3>
        <p>
          - Provision of the online offer, its functions and contents.<br />
          - Answering of contact requests and communication with users.<br />
          - Security measures.<br />
          - Reach Measurement/Marketing<br />
          <span class="tsmcom"></span>
        </p>
        <h3 id="dsg-general-terms">Definitions</h3>
        <p>
          "Personal data" means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an
          identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural
          person;<br />
          <br />
          "Processing" means any operation or set of operations which is performed upon personal data, whether or not by automatic means. The term is broad and covers virtually all data handling.<br />
          <br />
          "Pseudonymisation" means the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional
          information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person;<br />
          <br />
          "Profiling" means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person.<br />
          <br />
          "Controller" means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.<br />
          <br />
          "Processor" means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.<br />
        </p>
        <h3 id="dsg-general-legalbasis">Applicable legal basis</h3>
        <p>
          In accordance with Art. 13 GDPR we inform you about the legal basis of our data processing. If the legal basis is not stated in the data protection declaration, the following applies: The legal basis for obtaining consent is Art.
          6 Para. 1 lit. a and Art. 7 GDPR, the legal basis for processing for the purpose of fulfilling our services and implementing contractual measures and answering inquiries is Art. 6 Para. 1 lit. b GDPR, the legal basis for
          processing for the purpose of fulfilling our legal obligations is Art. 6 Para. 1 lit. c GDPR, and the legal basis for processing for the purpose of safeguarding our legitimate interests is Art. 6 Para. 1 lit. f GDPR. In the event
          that vital interests of the data subject or another natural person require the processing of personal data, Art. 6 para. 1 lit. d GDPR serves as the legal basis.
        </p>
        <h3 id="dsg-general-securitymeasures">Security measures</h3>
        <p>
          In accordance with Art. 32 GDPR and taking into account the state of the art, the implementation costs and the nature, scope, circumstances and purposes of the processing as well as the varying probability of occurrence and
          severity of the risk to the rights and freedoms of natural persons, we take appropriate technical and organizational measures to ensure a level of protection appropriate to the risk.<br />
          <br />
          These measures shall include, in particular, safeguarding the confidentiality, integrity and availability of data by controlling the physical access to the data, as well as the access, input, disclosure, security of availability
          and separation of the data relating to them. Furthermore, we have established procedures to ensure that the rights of data subjects are exercised, data is deleted, and we respond to any threats to the data. Furthermore, we take
          the protection of personal data into account as early as the development or selection of hardware, software and procedures, in accordance with the principle of data protection through technology design and through data
          protection-friendly presettings (Art. 25 GDPR).<br />
        </p>
        <h3 id="dsg-general-coprocessing">Cooperation with processors and third parties</h3>
        <p>
          If, in the course of our processing, we disclose data to other persons and companies (processors or third parties), transfer it to them or otherwise grant them access to the data, this is only done on the basis of a legal
          authorization (e.g. if a transfer of the data to third parties, such as payment service providers, is necessary for the performance of the contract in accordance with Art. 6 para. 1 lit. b GDPR), if you have consented, if a legal
          obligation provides for this or on the basis of our legitimate interests (e.g. when using agents, web hosts, etc.).<br />
          <br />
          If we commission third parties to process data on the basis of a so-called "contract processing agreement", this is done on the basis of Art. 28 GDPR.
        </p>
        <h3 id="dsg-general-thirdparty">Transfers to third countries</h3>
        <p>
          If we process data in a third country (i.e. outside the European Union (EU) or the European Economic Area (EEA)) or if this is done in the context of using the services of third parties or disclosure or transfer of data to third
          parties, this will only take place if it is done to fulfil our (pre-)contractual obligations, on the basis of your consent, on the basis of a legal obligation or on the basis of our legitimate interests. Subject to legal or
          contractual permissions, we will only process or transfer the data in a third country if the special requirements of Art. 44 ff. GDPR. This means that the processing is carried out, for example, on the basis of special guarantees,
          such as the officially recognized determination of a level of data protection corresponding to that of the EU (e.g. for the USA through the "Privacy Shield") or compliance with officially recognized special contractual obligations
          (so-called "standard contractual clauses").
        </p>
        <h3 id="dsg-general-rightssubject">Rights of the data subjects</h3>
        <p>
          You have the right to request confirmation as to whether or not data in question is being processed and to receive information about this data and to receive further information and a copy of the data in accordance with Art. 15 of
          the Data Protection Act.<br />
          <br />
          You have accordingly. Art. 16 GDPR the right to request the completion of data concerning you or the correction of incorrect data concerning you.<br />
          <br />
          In accordance with Art. 17 GDPR, you have the right to demand that data concerning you be deleted immediately, or alternatively, in accordance with Art. 18 GDPR, to demand that the processing of the data be restricted.<br />
          <br />
          You have the right to demand that the data concerning you, which you have made available to us, be received in accordance with Art. 20 GDPR and to demand its transfer to other responsible parties.<br />
          <br />
          You also have the right to lodge a complaint with the competent supervisory authority in accordance with Art. 77 GDPR.
        </p>
        <h3 id="dsg-general-revokeconsent">Right of withdrawal</h3>
        <p>You have the right to revoke consents granted in accordance with Art. 7 para. 3 GDPR with effect for the future.</p>
        <h3 id="dsg-general-object">Rights of objection</h3>
        <p>You can object to the future processing of data concerning you at any time in accordance with Art. 21 GDPR. The objection may in particular be made against processing for the purposes of direct advertising.</p>
        <h3 id="dsg-general-cookies">Cookies and right of objection for direct advertising</h3>
        <p>
          Cookies" are small files that are stored on the user's computer. Different information can be stored within the cookies. A cookie is primarily used to store information about a user (or the device on which the cookie is stored)
          during or after his visit to an online service. Temporary cookies, or "session cookies" or "transient cookies", are cookies that are deleted after a user leaves an online offer and closes his browser. In such a cookie, for
          example, the contents of a shopping cart in an online store or a login status can be stored. Cookies are described as "permanent" or "persistent" if they remain stored even after the browser is closed. For example, the login
          status can be saved if the user visits it after several days. Likewise, the interests of the users can be stored in such a cookie, which is used for range measurement or marketing purposes. Third-party cookies are cookies that are
          offered by providers other than the person responsible for operating the online service (otherwise, if it is only their cookies, it is called "first-party cookies").<br />
          <br />
          We may use temporary and permanent cookies and will provide information on this in our privacy policy.<br />
          <br />
          If users do not want cookies to be stored on their computer, they are asked to deactivate the corresponding option in the system settings of their browser. Stored cookies can be deleted in the system settings of the browser. The
          exclusion of cookies can lead to functional limitations of this online offer.<br />
          <br />
          A general objection to the use of cookies used for online marketing purposes can be declared for many of the services, especially in the case of tracking, via the US site http://www.aboutads.info/choices/ or the EU site
          http://www.youronlinechoices.com/. Furthermore, the storage of cookies can be achieved by deactivating them in the browser settings. Please note that in this case not all functions of this online offer can be used.
        </p>
        <h3 id="dsg-general-erasure">Deletion of data</h3>
        <p>
          The data processed by us will be deleted or limited in their processing in accordance with articles 17 and 18 GDPR. Unless expressly stated in this data protection declaration, the data stored by us will be deleted as soon as they
          are no longer required for their intended purpose and the deletion does not conflict with any statutory storage obligations. If the data are not deleted because they are required for other and legally permissible purposes, their
          processing will be restricted. This means that the data is blocked and not processed for other purposes. This applies, for example, to data that must be retained for commercial or tax law reasons.<br />
          <br />
          According to legal requirements in Germany, the storage is in particular for 10 years according to §§ 147 para. 1 AO, 257 para. 1 No. 1 and 4, para. 4 HGB (books, records, management reports, accounting vouchers, commercial books,
          documents relevant for taxation, etc.) and 6 years according to § 257 para. 1 No. 2 and 3, para. 4 HGB (commercial letters).<br />
        </p>
        <p></p>
        <h3 id="dsg-commercialpurpose">Business-related processing</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Additionally we process<br />
            - Contract data (e.g., object of contract, duration, customer category).<br />
            - Payment data (e.g., bank details, payment history)<br />
            by our customers, interested parties and business partners for the purpose of providing contractual services, service and customer care, marketing, advertising and market research.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-services-agency">Agency services</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >We process the data of our customers within the scope of our contractual services, which include conceptual and strategic consulting, campaign planning, software and design development/consulting or maintenance, implementation
            of campaigns and processes/handling, server administration, data analysis/consulting services and training services.<br />
            <br />
            We process inventory data (e.g., customer master data, such as names or addresses), contact data (e.g., e-mail, telephone numbers), content data (e.g., text entries, photographs, videos), contract data (e.g., subject matter of
            the contract, duration), payment data (e.g., bank details, payment history), usage and meta data (e.g., in the context of evaluation and performance measurement of marketing measures). As a matter of principle, we do not process
            special categories of personal data, unless they are part of a commissioned processing. Those affected include our customers, interested parties and their customers, users, website visitors or employees as well as third parties.
            The purpose of processing is to provide contractual services, billing and our customer service. The legal basis of the processing is derived from Art. 6 para. 1 letter b GDPR (contractual services), Art. 6 para. 1 letter f GDPR
            (analysis, statistics, optimization, security measures). We process data which are necessary for the justification and fulfilment of the contractual services and point out the necessity of their disclosure. Disclosure to
            external parties will only be made if it is necessary within the scope of an order. When processing the data provided to us within the scope of an order, we act in accordance with the instructions of the client and the legal
            requirements of an order processing in accordance with Art. 28 GDPR and do not process the data for any other purposes than those specified in the order.<br />
            <br />
            We delete the data after the expiry of statutory warranty and comparable obligations. The necessity of storing the data is reviewed every three years; in the case of statutory archiving obligations, deletion takes place after
            the expiry of these obligations (6 years in accordance with § 257 para. 1 HGB, 10 years in accordance with § 147 para. 1 AO). In the case of data that has been disclosed to us by the client within the scope of an order, we
            delete the data in accordance with the specifications of the order, in principle after the end of the order.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-services-contractualservices">Contractual services</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >We process the data of our contractual partners and interested parties as well as other principals, customers, clients, clients or contractual partners (uniformly referred to as "contractual partners") in accordance with Art. 6
            para. 1 lit. b. GDPR in order to provide them with our contractual or pre-contractual services. The data processed in this context, the nature, scope and purpose and the necessity of their processing, are determined by the
            underlying contractual relationship.<br /><br />
            The processed data includes the master data of our contractual partners (e.g., names and addresses), contact data (e.g., e-mail addresses and telephone numbers) as well as contract data (e.g., services used, contract contents,
            contractual communication, names of contact persons) and payment data (e.g., bank details, payment history).<br /><br />
            As a matter of principle, we do not process special categories of personal data, unless these are part of a commissioned or contractual processing.<br /><br />
            We process data which are necessary for the justification and fulfilment of the contractual services and point out the necessity of their disclosure, if this is not evident to the contractual partners. Disclosure to external
            persons or companies will only be made if it is necessary within the scope of a contract. When processing the data provided to us within the framework of an order, we act in accordance with the instructions of the client and the
            legal requirements.<br /><br />
            When using our online services, we may store the IP address and the time of the respective user action. The storage is based on our legitimate interests, as well as the interests of the users in protection against misuse and
            other unauthorized use. This data will not be passed on to third parties, unless it is necessary to pursue our claims in accordance with Art. 6 Par. 1 lit. f. GDPR or if there is a legal obligation to do so in accordance with
            Art. 6 para. 1 lit. c. GDPR.<br /><br />
            The data will be deleted when the data is no longer necessary for the fulfilment of contractual or statutory duties of care and for dealing with any warranty or comparable duties, whereby the necessity of keeping the data will
            be reviewed every three years; otherwise the statutory duties of safekeeping apply.<br /><br />
            <br />
          </span>
        </p>
        <p></p>
        <h3 id="dsg-administration">Administration, financial accounting, office organization, contact management</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >We process data within the framework of administrative tasks as well as the organization of our operations, financial accounting and compliance with legal obligations, such as archiving. In doing so, we process the same data
            that we process within the scope of providing our contractual services. The basis for processing is article 6 paragraph 1 letter c. GDPR, Art. 6 para. 1 lit. f. GDPR. Customers, interested parties, business partners and website
            visitors are affected by the processing. The purpose of and our interest in processing lies in the administration, financial accounting, office organization, archiving of data, i.e. tasks that serve to maintain our business
            activities, perform our tasks and provide our services. The deletion of the data with regard to contractual services and contractual communication corresponds to the data mentioned in these processing activities.<br />
            <br />
            We disclose or transfer data to the tax authorities, consultants, such as tax consultants or auditors, as well as other fee agencies and payment service providers.<br />
            <br />
            Furthermore, we store information on suppliers, event organizers and other business partners on the basis of our business interests, e.g. for the purpose of contacting them at a later date. We store these mostly company-related
            data permanently.<br />
          </span>
        </p>
        <p></p>
        <h3 id="dsg-contact">Establishing contact</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >When contacting us (e.g. via contact form, e-mail, telephone or via social media), the user's details are processed for the purpose of processing the contact request and its handling in accordance with Art. 6 Para. 1 lit. b)
            GDPR. The user's details may be stored in a customer relationship management system ("CRM system") or comparable inquiry organization.<br />
            <br />
            We will delete the inquiries if they are no longer required. We review the necessity every two years; furthermore, the statutory archiving obligations apply.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-hostingprovider">Hosting and e-mailing</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >The hosting services we use serve to provide the following services: Infrastructure and platform services, computing capacity, storage space and database services, e-mail dispatch, security services as well as technical
            maintenance services that we use for the purpose of operating this online offer.<br />
            <br />
            In doing so, we or our hosting provider process inventory data, contact data, content data, contract data, usage data, meta and communication data of customers, interested parties and visitors of this online offer on the basis
            of our legitimate interests in an efficient and secure provision of this online offer in accordance with Art. 6 para. 1 lit. f GDPR in conjunction with Art. 28 GDPR (conclusion of contract processing agreement).</span
          >
        </p>
        <p></p>
        <h3 id="dsg-logfiles">Collection of access data and log files</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >We, or our hosting provider, on the basis of our legitimate interests as defined in Art. 6 para. 1 lit. f. GDPR data about every access to the server on which this service is located (so-called server log files). The access
            data includes the name of the accessed website, file, date and time of access, transferred data volume, notification of successful access, browser type and version, the user's operating system, referrer URL (the previously
            visited site), IP address and the requesting provider.<br />
            <br />
            For security reasons (e.g. to clarify misuse or fraud), log file information is stored for a maximum of 7 days and then deleted. Data whose further storage is required for evidence purposes is excluded from deletion until the
            respective incident has been finally clarified.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-matomo">Range measurement with Matomo</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Within the scope of Matomo's coverage analysis, based on our legitimate interests (i.e. interest in the analysis, optimization and economic operation of our online offer in the sense of Art. 6 para. 1 lit. f. GDPR) the
            following data is processed: the type of browser and browser version you use, the operating system you use, your country of origin, date and time of the server request, the number of visits, your length of stay on the website
            and the external links you activated. The user's IP address is made anonymous before it is saved.<br />
            <br />
            Matomo uses cookies, which are stored on the user's computer and which enable an analysis of the use of our online offer by the users. Pseudonymous user profiles of the users can be created from the processed data. The cookies
            have a storage period of one week. The information generated by the cookie about your use of this website is only stored on our server and is not passed on to third parties.<br />
            <br />
            Users can object to the anonymous data collection by the Matomo program at any time with effect for the future by clicking on the link below. In this case a so-called opt-out cookie will be stored in their browser, which means
            that Matomo will no longer collect any session data. However, if users delete their cookies, the opt-out cookie will also be deleted and must therefore be reactivated by users.<br />
            <br />
            The logs with the users' data will be deleted after 6 months at the latest.<br />
            <br />
            <iframe id="piwik" src="https://www.colorful-bytes.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de"> </iframe>
          </span>
        </p>
        <p></p>
        <h3 id="dsg-socialmedia">Online presence in social media</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >We maintain online presences within social networks and platforms in order to be able to communicate with the customers, interested parties and users active there and to inform them about our services. When accessing the
            respective networks and platforms, the terms and conditions and data processing guidelines of their respective operators apply.<br />
            <br />
            Unless otherwise stated in our privacy policy, we process the data of users if they communicate with us within the social networks and platforms, e.g. write articles on our online presences or send us messages.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-thirdparty-einleitung">Integration of third party services and content</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >We set within our online offer on the basis of our legitimate interests (i.e. interest in the analysis, optimization and economic operation of our online offer within the meaning of Art. 6 para. 1 lit. f. GDPR), we use content
            or service offers from third parties in order to integrate their content and services, such as videos or fonts (hereinafter uniformly referred to as "content").<br />
            <br />
            This always presupposes that the third-party providers of such content are aware of the IP address of the users, as without the IP address they would not be able to send the content to their browsers. The IP address is therefore
            necessary for the display of this content. We make every effort to use only such content whose respective providers use the IP address only to deliver the content. Third party providers may also use so-called pixel tags
            (invisible graphics, also known as "web beacons") for statistical or marketing purposes. The "pixel tags" can be used to evaluate information such as visitor traffic on the pages of this website. The pseudonymous information may
            also be stored in cookies on the user's device and may contain technical information about the browser and operating system, referring websites, visiting times and other details about the use of our online offer, as well as
            being linked to such information from other sources.</span
          >
        </p>
        <a href="https://datenschutz-generator.de" class="dsg1-5" rel="nofollow" target="_blank">German Privacy Policy Declaration was created with Datenschutz-Generator.de. Translated by Colorful Bytes GmbH</a>
      </div>
      <div v-else>
        <h3 id="dsg-general-intro"></h3>
        <p>
          Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen
          und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder
          „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
          <br />
          <br />
        </p>
        <h3 id="dsg-general-controller">Verantwortlicher</h3>
        <p>
          <span class="tsmcontroller"
            >Colorful Bytes GmbH<br />
            Blumenstraße 20<br />
            74906 Bad Rappenau<br />
            E-Mailadresse:
            <a href="#" class="cryptedmail" data-name="info" data-domain="colorful-bytes" data-tld="com" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a
            ><br />
            Geschäftsführer: Dr. Monika Pobiruchin, Martin Wiesner, Richard Zowalla, Daniel Zsebedits<br />
            <router-link :to="{ name: 'colorful-bytes-home', hash: '#footer' }">{{ $t("general.imprint") }}</router-link>
            <br />
          </span>
        </p>
        <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten:</h3>
        <p>
          - Bestandsdaten (z.B., Namen, Adressen).<br />
          - Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />
          - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br />
          - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br />
          - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).<br />
        </p>
        <h3 id="dsg-general-datasubjects">Kategorien betroffener Personen</h3>
        <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).<br /></p>
        <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
        <p>
          - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.<br />
          - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br />
          - Sicherheitsmaßnahmen.<br />
          - Reichweitenmessung/Marketing<br />
          <span class="tsmcom"></span>
        </p>
        <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten</h3>
        <p>
          „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die
          direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert
          werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.<br />
          <br />
          „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
          Daten.<br />
          <br />
          „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können,
          sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder
          identifizierbaren natürlichen Person zugewiesen werden.<br />
          <br />
          „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen,
          zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
          analysieren oder vorherzusagen.<br />
          <br />
          Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
          entscheidet, bezeichnet.<br />
          <br />
          „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.<br />
        </p>
        <h3 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h3>
        <p>
          Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die Einholung
          von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1
          lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist
          Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO
          als Rechtsgrundlage.
        </p>
        <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
        <p>
          Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
          Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.<br />
          <br />
          Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe,
          der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner
          berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch
          datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).<br />
        </p>
        <h3 id="dsg-general-coprocessing">Zusammenarbeit mit Auftragsverarbeitern und Dritten</h3>
        <p>
          Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur
          auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine
          rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.). <br />
          <br />
          Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.
        </p>
        <h3 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h3>
        <p>
          Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw.
          Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer
          berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO
          verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder
          Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
        </p>
        <h3 id="dsg-general-rightssubject">Rechte der betroffenen Personen</h3>
        <p>
          Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.<br />
          <br />
          Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.<br />
          <br />
          Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu verlangen.<br />
          <br />
          Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern. <br />
          <br />
          Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
        </p>
        <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
        <p>Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen</p>
        <h3 id="dsg-general-object">Widerspruchsrecht</h3>
        <p>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.</p>
        <h3 id="dsg-general-cookies">Cookies und Widerspruchsrecht bei Direktwerbung</h3>
        <p>
          Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem Nutzer
          (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden Cookies
          bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert
          werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen
          aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen
          Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).<br />
          <br />
          Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung auf.<br />
          <br />
          Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den
          Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.<br />
          <br />
          Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite
          <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die EU-Seite <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren kann die
          Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
        </p>
        <h3 id="dsg-general-erasure">Löschung von Daten</h3>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns
          gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für andere
          und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen
          Gründen aufbewahrt werden müssen.<br />
          <br />
          Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handelsbücher, für
          Besteuerung relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe). <br />
          <br />
          Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben,
          etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und Fernsehleistungen, die an Nichtunternehmer in
          EU-Mitgliedstaaten erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
        </p>
        <p></p>
        <h3 id="dsg-commercialpurpose">Geschäftsbezogene Verarbeitung</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Zusätzlich verarbeiten wir<br />
            - Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).<br />
            - Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)<br />
            von unseren Kunden, Interessenten und Geschäftspartner zwecks Erbringung vertraglicher Leistungen, Service und Kundenpflege, Marketing, Werbung und Marktforschung.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-services-agency">Agenturdienstleistungen</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Wir verarbeiten die Daten unserer Kunden im Rahmen unserer vertraglichen Leistungen zu denen konzeptionelle und strategische Beratung, Kampagnenplanung, Software- und Designentwicklung/-beratung oder Pflege, Umsetzung von
            Kampagnen und Prozessen/ Handling, Serveradministration, Datenanalyse/ Beratungsleistungen und Schulungsleistungen gehören.<br />
            <br />
            Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern), Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos), Vertragsdaten (z.B.,
            Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten (z.B. im Rahmen der Auswertung und Erfolgsmessung von Marketingmaßnahmen). Besondere Kategorien personenbezogener
            Daten verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten Verarbeitung sind. Zu den Betroffenen gehören unsere Kunden, Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder Mitarbeiter
            sowie Dritte. Der Zweck der Verarbeitung besteht in der Erbringung von Vertragsleistungen, Abrechnung und unserem Kundenservice. Die Rechtsgrundlagen der Verarbeitung ergeben sich aus Art. 6 Abs. 1 lit. b DSGVO (vertragliche
            Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse, Statistik, Optimierung, Sicherheitsmaßnahmen). Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen auf die
            Erforderlichkeit ihrer Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn sie im Rahmen eines Auftrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten handeln wir entsprechend
            den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28 DSGVO und verarbeiten die Daten zu keinen anderen, als den auftragsgemäßen Zwecken.<br />
            <br />
            Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen Archivierungspflichten
            erfolgt die Löschung nach deren Ablauf (6 J, gem. § 257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1 AO). Im Fall von Daten, die uns gegenüber im Rahmen eines Auftrags durch den Auftraggeber offengelegt wurden, löschen wir die Daten
            entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-services-contractualservices">Vertragliche Leistungen</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie anderer Auftraggeber, Kunden, Mandanten, Klienten oder Vertragspartner (einheitlich bezeichnet als „Vertragspartner“) entsprechend Art. 6 Abs. 1 lit. b.
            DSGVO, um ihnen gegenüber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach
            dem zugrundeliegenden Vertragsverhältnis. <br />
            <br />
            Zu den verarbeiteten Daten gehören die Stammdaten unserer Vertragspartner (z.B., Namen und Adressen), Kontaktdaten (z.B. E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B., in Anspruch genommene Leistungen,
            Vertragsinhalte, vertragliche Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B., Bankverbindungen, Zahlungshistorie). <br />
            <br />
            Besondere Kategorien personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten oder vertragsgemäßen Verarbeitung sind. <br />
            <br />
            Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe, sofern diese für die Vertragspartner nicht evident ist, hin. Eine
            Offenlegung an externe Personen oder Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten, handeln wir entsprechend den Weisungen
            der Auftraggeber sowie der gesetzlichen Vorgaben. <br />
            <br />
            Im Rahmen der Inanspruchnahme unserer Onlinedienste, können wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung speichern. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der
            Interessen der Nutzer am Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche gem. Art. 6 Abs. 1 lit. f. DSGVO
            erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.<br />
            <br />
            Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder gesetzlicher Fürsorgepflichten sowie für den Umgang mit etwaigen Gewährleistungs- und vergleichbaren Pflichten nicht mehr erforderlich sind, wobei
            die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft wird; im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.<br />
          </span>
        </p>
        <p></p>
        <h3 id="dsg-administration">Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir dieselben Daten, die
            wir im Rahmen der Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden, Interessenten,
            Geschäftspartner und Websitebesucher betroffen. Der Zweck und unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also Aufgaben die der Aufrechterhaltung
            unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf vertragliche Leistungen und die vertragliche Kommunikation entspricht den, bei diesen
            Verarbeitungstätigkeiten genannten Angaben.<br />
            <br />
            Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.<br />
            <br />
            Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen
            Daten, speichern wir grundsätzlich dauerhaft.<br />
          </span>
        </p>
        <p></p>
        <h3 id="dsg-contact">Kontaktaufnahme</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO
            verarbeitet. Die Angaben der Nutzer können in einem Customer-Relationship-Management System ("CRM System") oder vergleichbarer Anfragenorganisation gespeichert werden.<br />
            <br />
            Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-hostingprovider">Hosting und E-Mail-Versand</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, E-Mail-Versand,
            Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen. <br />
            <br />
            Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf
            Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).</span
          >
        </p>
        <p></p>
        <h3 id="dsg-logfiles">Erhebung von Zugriffsdaten und Logfiles</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte
            Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
            Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.<br />
            <br />
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu
            Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-matomo">Reichweitenmessung mit Matomo</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Im Rahmen der Reichweitenanalyse von Matomo werden auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
            f. DSGVO) die folgenden Daten verarbeitet: der von Ihnen verwendete Browsertyp und die Browserversion, das von Ihnen verwendete Betriebssystem, Ihr Herkunftsland, Datum und Uhrzeit der Serveranfrage, die Anzahl der Besuche, Ihre
            Verweildauer auf der Website sowie die von Ihnen betätigten externen Links. Die IP-Adresse der Nutzer wird anonymisiert, bevor sie gespeichert wird. <br />
            <br />
            Matomo verwendet Cookies, die auf dem Computer der Nutzer gespeichert werden und die eine Analyse der Benutzung unseres Onlineangebotes durch die Nutzer ermöglichen. Dabei können aus den verarbeiteten Daten pseudonyme
            Nutzungsprofile der Nutzer erstellt werden. Die Cookies haben eine Speicherdauer von einer Woche. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden nur auf unserem Server gespeichert und
            nicht an Dritte weitergegeben.<br />
            <br />
            Nutzer können der anonymisierten Datenerhebung durch das Programm Matomo jederzeit mit Wirkung für die Zukunft widersprechen, indem sie auf den untenstehenden Link klicken. In diesem Fall wird in ihrem Browser ein sog.
            Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten mehr erhebt. Wenn Nutzer ihre Cookies löschen, so hat dies jedoch zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und daher von den Nutzern
            erneut aktiviert werden muss.<br />
            <br />
            Die Logs mit den Daten der Nutzer werden nach spätestens 6 Monaten gelöscht.<br />
            <br />
            <iframe id="piwik" src="https://www.colorful-bytes.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de"> </iframe>
          </span>
        </p>
        <p></p>
        <h3 id="dsg-socialmedia">Onlinepräsenzen in sozialen Medien</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen informieren zu können. Beim Aufruf der
            jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber. <br />
            <br />
            Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren
            Onlinepräsenzen verfassen oder uns Nachrichten zusenden.</span
          >
        </p>
        <p></p>
        <h3 id="dsg-thirdparty-einleitung">Einbindung von Diensten und Inhalten Dritter</h3>
        <p></p>
        <p>
          <span class="ts-muster-content"
            >Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
            DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”). <br />
            <br />
            Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung
            dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so genannte Pixel-Tags
            (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden.
            Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere
            Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.</span
          >
        </p>
        <a href="https://datenschutz-generator.de" class="dsg1-5" rel="nofollow" target="_blank">Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  }
};
</script>

<style lang="scss" scoped>
iframe#piwik {
  border: 0;
  width: 100%;
}

@media (max-width: 431px) {
  iframe#piwik {
    height: 440px;
  }
}

@media (min-width: 432px) and (max-width: $xs-lower) {
  iframe#piwik {
    height: 328px;
  }
}

@media (min-width: $xs) and (max-width: $lg-lower) {
  iframe#piwik {
    height: 296px;
  }
}

@media (min-width: $lg) {
  iframe#piwik {
    height: 224px;
  }
}
</style>
