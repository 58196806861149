<template>
  <div id="partner">
    <i18n path="tweet-point.partnerHeadline" tag="h1" class="headline text-center">
      <template v-slot:digitaldabei>
        <strong><strong>#digitaldabei</strong></strong>
      </template>
    </i18n>

    <div class="partner-logos text-center">
      <a href="https://www.dmea.de/" rel="noreferrer" target="_blank"><img src="@/assets/img/partner/dmea.webp" alt="DMEA" /></a>
      <a href="https://www.fruitlogistica.com/" rel="noreferrer" target="_blank"><img class="mb-2 mt-2" src="@/assets/img/partner/fruit-logistica.webp" alt="Fruit Logistica" /></a>
      <a href="https://gmds.de/" rel="noreferrer" target="_blank"><img src="@/assets/img/partner/gmds2017.webp" alt="GMDS 2017" /></a>
      <a href="https://gmds.de/" rel="noreferrer" target="_blank"><img src="@/assets/img/partner/gmds2018.webp" alt="GMDS 2018" /></a>
      <a href="https://gmds.de/" rel="noreferrer" target="_blank"><img src="@/assets/img/partner/gmds2019.webp" alt="GMDS 2019" /></a>
      <a href="https://gmds.de/" rel="noreferrer" target="_blank"><img src="@/assets/img/partner/gmds2023.webp" alt="GMDS 2023" /></a>
      <a href="https://www.smartcountry.berlin/" rel="noreferrer" target="_blank"><img src="@/assets/img/partner/sccon.webp" alt="Smart Country Convention" /></a>
      <a href="#" target="_blank"><img src="@/assets/img/partner/hec2016.webp" alt="Health Exploring Complexity 2016" /></a>
      <a href="#" target="_blank"><img class="mb-2 mt-2" src="@/assets/img/partner/meetit2019.webp" alt="Meet IT 2019" /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partner"
};
</script>

<style lang="scss" scoped>
h1.headline,
h2.headline {
  margin-bottom: 2rem;
}

.headline {
  font-weight: 300;
  letter-spacing: -0.05rem;
}

.partner-logos {
  a {
    margin: 20px 15px 0;
  }

  img {
    width: 240px;
  }
}
</style>
