import Vue from "vue";
import Vuex from "vuex";

import { SET_ACCEPTED } from "./mutation-types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cookiesAccepted: false
  },
  mutations: {
    [SET_ACCEPTED](state, cookiesAccepted) {
      state.cookiesAccepted = cookiesAccepted;
    }
  }
});
