<template>
  <div>
    <b-carousel vcontrols indicators v-model="slide" background="#c0c1c8">
      <div class="carousel-item">
        <div id="slide-ebene-3" class="full-width">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 carousel-description">
                <h2 class="carousel-heading">{{ $t("tourist-point.title") }}</h2>
                <i18n path="tourist-point.carouselProgram" tag="p" class="lead">
                  <template v-slot:tip>
                    <strong>{{ $t("general.tip") }}:</strong>
                  </template>
                </i18n>
                <router-link :to="{ name: 'tourist-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-7 vertical-layouts tv-container">
                <div class="tv tv-highlight" @click="showModal(touristInformation, 'lg')">
                  <img class="d-block img-fluid full-width" :src="touristInformation" alt="Vertikales Layout" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-gmds-2019" class="full-width">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 carousel-description">
                <h2 class="carousel-heading">{{ $t("tourist-point.title") }}</h2>
                <p class="lead">{{ $t("tourist-point.carouselMultiDisplay") }}</p>
                <router-link :to="{ name: 'tourist-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-7 vertical-layouts tv-container">
                <div class="tv" @click="showModal(displayWall, 'lg')">
                  <img class="d-block img-fluid full-width" :src="displayWall" alt="Darstellung Displaywand" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-news-wall" class="full-width">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 carousel-description">
                <h2 class="carousel-heading">{{ $t("tourist-point.title") }}</h2>
                <p class="lead">{{ $t("tourist-point.carouselNewswall") }}</p>
                <router-link :to="{ name: 'tourist-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-8 tv-container">
                <div class="tv" @click="showModal(regionenSpiegel)">
                  <img class="d-block img-fluid full-width" :src="regionenSpiegel" alt="Twitterwall" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-carousel>
    <b-modal hide-header hide-footer ref="modal-lg" size="lg" content-class="cb-modal-content" body-class="cb-modal-body">
      <a @click="hideModal('lg')"><img class="d-block img-fluid full-width" :src="currentImage" alt="Twitterwall" /></a>
    </b-modal>
    <b-modal hide-header hide-footer ref="modal-xl" size="xl" content-class="cb-modal-content" body-class="cb-modal-body">
      <a @click="hideModal('xl')"><img class="d-block img-fluid full-width" :src="currentImage" alt="Twitterwall" /></a>
    </b-modal>
  </div>
</template>

<script>
import displayWall from "@/assets/img/tourist-point/Tourist-Point-Displaywand-Kueste.webp";
import touristInformation from "@/assets/img/tourist-point/Tourist-Point-Touristinformation-Berge.webp";
import regionenSpiegel from "@/assets/img/tourist-point/Tourist-Point-Regionenspiegel-Mitteldeutschland.webp";

export default {
  data() {
    return {
      slide: 0,
      currentImage: ""
    };
  },

  computed: {
    displayWall() {
      return displayWall;
    },

    touristInformation() {
      return touristInformation;
    },

    regionenSpiegel() {
      return regionenSpiegel;
    }
  },

  methods: {
    showModal(image, size = "xl") {
      this.currentImage = image;
      this.$refs["modal-" + size].show();
    },

    hideModal(size) {
      this.$refs["modal-" + size].hide();
    }
  },

  created() {
    if (this.$route.query.slide != null) {
      this.slide = parseInt(this.$route.query.slide);
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-description {
  margin-bottom: 2rem;
}

.carousel-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

.tv {
  cursor: pointer;
}

.carousel-item {
  & > div {
    min-height: 640px;
  }
}

@media (max-width: $sm-lower) {
  .carousel {
    margin-bottom: 8rem;

    .carousel-item {
      .container {
        padding-top: 3rem;
        padding-bottom: 4rem;
      }
    }

    .tv {
      border-width: 6px;
    }
  }
}

@media (min-width: $sm) and (max-width: $lg-lower) {
  .carousel-item {
    & > div {
      min-height: 960px;
    }
  }
}

@media (min-width: $sm) {
  .carousel {
    margin-bottom: 8rem;

    .carousel-heading {
      font-size: 48px;
    }

    .carousel-item {
      & > div {
        display: flex;
        align-items: center;
      }

      .container {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
    }

    .tv {
      border-width: 8px;
    }
  }
}

@media (min-width: $lg) {
  .carousel {
    margin-bottom: 8rem;

    .carousel-heading {
      margin-top: 5rem;
    }

    .tv {
      border-width: 8px;
      transform: rotate3d(0, 1, 0, -15deg);
    }
  }
}

.tv-container {
  perspective: 1000px;
}

.tv {
  border-radius: 3px;
  border: solid #13202c;
  border-right-color: #060b0f;
  border-bottom-color: #060b0f;
  box-shadow: 1px 1px 10px black;

  img {
    z-index: -1000;
  }
}

#slide-meetit {
  @extend .bg-gradient-cyan-green-vertical;
  //background: #00B6F6;
}

#slide-hec {
  @extend .bg-gradient-cb;
}

#slide-news-wall {
  @extend .bg-gradient-white-grey-horizontal;
}

#slide-vertical {
  @extend .bg-gradient-cyan-green-diagonal;

  .tv {
    width: 29%;
    border-width: 4px;
    box-shadow: 1px 1px 4px black;
  }

  .tv-highlight {
    width: 39%;
  }

  .vertical-layouts {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#slide-ebene-3 {
  @extend .bg-gradient-white-grey-horizontal;

  .tv {
    width: 29%;
    border-width: 4px;
    box-shadow: 1px 1px 4px black;
  }

  .tv-highlight {
    width: 45%;
  }

  .vertical-layouts {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#slide-gmds-2019 {
  @extend .bg-gradient-white-grey-horizontal;

  .tv {
    width: 60%;
    border-width: 4px;
    box-shadow: 1px 1px 4px black;
  }

  .vertical-layouts {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.cb-modal-content {
  border-radius: 0;
}

.cb-modal-body {
  padding: 0;
  cursor: pointer;
}
</style>
