<template>
  <div>
    <b-carousel vcontrols indicators v-model="slide" background="#c0c1c8">
      <div class="carousel-item active">
        <div id="slide-demo" class="full-width bg-gradient-cyan-green-horizontal text-white">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 carousel-description">
                <h2 class="carousel-heading">{{ $t("tweet-point.title") }}</h2>
                <i18n path="tweet-point.carouselTwitterwall" tag="p" class="lead">
                  <template v-slot:twitterwall>
                    <strong>{{ $t("tweet-point.twitterwall") }}</strong>
                  </template>
                  <template v-slot:ndash> &ndash; </template>
                </i18n>
                <router-link :to="{ name: 'tweet-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-white-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-8 tv-container">
                <div class="tv" @click="showModal(demo, 'xl')">
                  <img class="d-block img-fluid full-width" :src="demo" alt="Veranstaltungsprogramm mit integrierter Twitterwall als Digital Signage Lösung" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-ebene-3" class="full-width">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 carousel-description">
                <h2 class="carousel-heading">{{ $t("tweet-point.title") }}</h2>
                <i18n path="tweet-point.carouselProgram" tag="p" class="lead">
                  <template v-slot:tip>
                    <strong>{{ $t("general.tip") }}:</strong>
                  </template>
                </i18n>
                <router-link :to="{ name: 'tweet-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-7 vertical-layouts tv-container">
                <div class="tv tv-highlight" @click="showModal(ebeneDrei, 'lg')">
                  <img class="d-block img-fluid full-width" :src="ebeneDrei" alt="Vertikales Layout" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-vertical" class="full-width text-white">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 carousel-description">
                <h2 class="carousel-heading">{{ $t("tweet-point.title") }}</h2>
                <p class="lead">{{ $t("tweet-point.carouselSponsor") }}</p>
                <router-link :to="{ name: 'tweet-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-white-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-8 vertical-layouts tv-container">
                <div class="tv" @click="showModal(scconArena, 'lg')">
                  <img class="d-block img-fluid full-width" :src="scconArena" alt="Vertikales Layout" />
                </div>
                <div class="tv tv-highlight" @click="showModal(sccon, 'lg')">
                  <img class="d-block img-fluid full-width" :src="sccon" alt="Vertikales Layout" />
                </div>
                <div class="tv" @click="showModal(scconForum, 'lg')">
                  <img class="d-block img-fluid full-width" :src="scconForum" alt="Vertikales Layout" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-gmds-2019" class="full-width">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 carousel-description">
                <h2 class="carousel-heading">{{ $t("tweet-point.title") }}</h2>
                <p class="lead">{{ $t("tweet-point.carouselMultiDisplay") }}</p>
                <router-link :to="{ name: 'tweet-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-cyan">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-8 tv-container">
                <div class="tv" @click="showModal(displaywand, 'xl')">
                  <img class="d-block img-fluid full-width" :src="displaywand" alt="Darstellung Displaywand" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-hec" class="full-width">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 carousel-description">
                <h2 class="carousel-heading">{{ $t("tweet-point.title") }}</h2>
                <i18n path="tweet-point.carouselAds" tag="p" class="lead">
                  <template v-slot:tweet-point>
                    <strong>{{ $t("tweet-point.title") }}:</strong>
                  </template>
                  <template v-slot:shy>&shy;</template>
                </i18n>
                <router-link :to="{ name: 'tweet-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-8 tv-container">
                <div class="tv" @click="showModal(hec)">
                  <img class="d-block img-fluid full-width" :src="hec" alt="Infodisplay für Konferenzen und Kongresse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-job" class="full-width bg-gradient-cyan-green-horizontal text-white">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 carousel-description">
                <h2 class="carousel-heading">{{ $t("tweet-point.title") }}</h2>
                <p class="lead">{{ $t("tweet-point.carouselJobwall") }}</p>
                <router-link :to="{ name: 'tweet-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-white-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-8 tv-container">
                <div class="tv" @click="showModal(jobWall)">
                  <img class="d-block img-fluid full-width" :src="jobWall" alt="Veranstaltungsprogramm mit integrierter Twitterwall als Digital Signage Lösung" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div id="slide-news-wall" class="full-width text-white">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 carousel-description">
                <h2 class="carousel-heading">{{ $t("tweet-point.title") }}</h2>
                <p class="lead">{{ $t("tweet-point.carouselNewswall") }}</p>
                <router-link :to="{ name: 'tweet-point-home', hash: '#features' }" class="btn btn-lg btn-outline-cb-white-magenta">{{ $t("general.moreInfo") }}</router-link>
              </div>
              <div class="col-lg-8 tv-container">
                <div class="tv" @click="showModal(newsWall)">
                  <img class="d-block img-fluid full-width" :src="newsWall" alt="Twitterwall" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-carousel>
    <b-modal hide-header hide-footer ref="modal-lg" size="lg" content-class="cb-modal-content" body-class="cb-modal-body">
      <a @click="hideModal('lg')"><img class="d-block img-fluid full-width" :src="currentImage" alt="Twitterwall" /></a>
    </b-modal>
    <b-modal hide-header hide-footer ref="modal-xl" size="xl" content-class="cb-modal-content" body-class="cb-modal-body">
      <a @click="hideModal('xl')"><img class="d-block img-fluid full-width" :src="currentImage" alt="Twitterwall" /></a>
    </b-modal>
  </div>
</template>

<script>
import displaywand from "@/assets/img/displaywand.webp";
import ebeneDrei from "@/assets/img/screenshot-ebene-3.webp";
import jobWall from "@/assets/img/screenshot-jobwall.webp";
import newsWall from "@/assets/img/screenshot-news-wall.webp";
import sccon from "@/assets/img/screenshot-sccon.webp";
import scconArena from "@/assets/img/screenshot-sccon-arena.webp";
import scconForum from "@/assets/img/screenshot-sccon-forum.webp";
import demo from "@/assets/img/screenshot-demo.webp";
import hec from "@/assets/img/screenshot-hec.webp";

export default {
  data() {
    return {
      slide: 0,
      currentImage: ""
    };
  },

  computed: {
    ebeneDrei() {
      return ebeneDrei;
    },
    jobWall() {
      return jobWall;
    },
    newsWall() {
      return newsWall;
    },
    sccon() {
      return sccon;
    },
    scconArena() {
      return scconArena;
    },
    scconForum() {
      return scconForum;
    },
    demo() {
      return demo;
    },
    displaywand() {
      return displaywand;
    },
    hec() {
      return hec;
    }
  },

  methods: {
    showModal(image, size = "xl") {
      this.currentImage = image;
      this.$refs["modal-" + size].show();
    },

    hideModal(size) {
      this.$refs["modal-" + size].hide();
    }
  },

  created() {
    if (this.$route.query.slide != null) {
      this.slide = parseInt(this.$route.query.slide);
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-description {
  margin-bottom: 2rem;
}

.carousel-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

.tv {
  cursor: pointer;
}

.carousel-item {
  & > div {
    min-height: 640px;
  }
}

@media (max-width: $sm-lower) {
  .carousel {
    margin-bottom: 8rem;

    .carousel-item {
      .container {
        padding-top: 3rem;
        padding-bottom: 4rem;
      }
    }

    .tv {
      border-width: 6px;
    }
  }
}

@media (min-width: $sm) and (max-width: $lg-lower) {
  .carousel-item {
    & > div {
      min-height: 960px;
    }
  }
}

@media (min-width: $sm) {
  .carousel {
    margin-bottom: 8rem;

    .carousel-heading {
      font-size: 48px;
    }

    .carousel-item {
      & > div {
        display: flex;
        align-items: center;
      }

      .container {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
    }

    .tv {
      border-width: 8px;
    }
  }
}

@media (min-width: $lg) {
  .carousel {
    margin-bottom: 8rem;

    .carousel-heading {
      margin-top: 5rem;
    }

    .tv {
      border-width: 8px;
      transform: rotate3d(0, 1, 0, -15deg);
    }
  }
}

.tv-container {
  perspective: 1000px;
}

.tv {
  border-radius: 3px;
  border: solid #13202c;
  border-right-color: #060b0f;
  border-bottom-color: #060b0f;
  box-shadow: 1px 1px 10px black;

  img {
    z-index: -1000;
  }
}

#slide-meetit {
  @extend .bg-gradient-cyan-green-vertical;
  //background: #00B6F6;
}

#slide-hec {
  @extend .bg-gradient-cb;
}

#slide-news-wall {
  background: #582b0e url("~@/assets/img/newspaper-darker_1080.webp") center center / cover no-repeat;
}

#slide-vertical {
  @extend .bg-gradient-cyan-green-diagonal;

  .tv {
    width: 29%;
    border-width: 4px;
    box-shadow: 1px 1px 4px black;
  }

  .tv-highlight {
    width: 39%;
  }

  .vertical-layouts {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#slide-ebene-3 {
  @extend .bg-gradient-white-grey-horizontal;

  .tv {
    width: 29%;
    border-width: 4px;
    box-shadow: 1px 1px 4px black;
  }

  .tv-highlight {
    width: 45%;
  }

  .vertical-layouts {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#slide-gmds-2019 {
  @extend .bg-gradient-white-grey-horizontal;
}
</style>

<style lang="scss">
.cb-modal-content {
  border-radius: 0;
}

.cb-modal-body {
  padding: 0;
  cursor: pointer;
}
</style>
